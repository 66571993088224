import { useEffect, useState } from "react";
import axios from "axios";

import EachLogistics from "../Components/EachLogistics"; // You need to create this component
import SideNav from "../Components/SideNav";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import FMFooter from "../Components/FMFooter";
import SearchOptions from "../Components/SearchOptions";
import "../styles/products.css";

export default function LogisticsOrders() {
  const [isLoading, setIsLoading] = useState(true);
  const [logistics, setLogistics] = useState([]);
  const [searchMessage, setSearchMessage] = useState("");
  const [backendError, setBackendError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    function handleSuccess(position) {
      const { latitude, longitude } = position.coords;

      axios
        .get(
          `api/v1/logistics?latitude=${latitude}&longitude=${longitude}&name=${searchTerm}`
        )
        .then((response) => {
          const logisticsData = response.data.logisticsCompanies;

          if (logisticsData.length > 0) {
            // Parse the distance field as a number
            logisticsData.forEach((logistic) => {
              logistic.distanceMeters = parseFloat(
                logistic.officeLocations.distanceMeters
              );
            });

            setLogistics(logisticsData);
            // console.log(logisticsData)
            setBackendError(null);
          } else {
            setBackendError(
              response.data.message ||
                "No logistics services match your search criteria."
            );
          }

          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching logistics:", error);
          setIsLoading(false);
          setBackendError(
            "Could not determine your location or no logistics services are available in your area. Please check back later."
          );
        });
    }

    function handleError(error) {
      console.error("Error getting location:", error);
      setIsLoading(false);
      setSearchMessage("Could not determine your location.");
    }

    navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
  }, [searchTerm, setSearchMessage]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div>
      <Navbar />
      <SearchOptions />
      <div>
        <input
          type="text"
          placeholder="Search logistics services by name"
          value={searchTerm}
          onChange={handleSearchChange}
          className="searchField"
        />
      </div>
      {isLoading ? (
        <div className="loading">
          <div className="loading-spinner"></div>
        </div>
      ) : (
        <div className="prodPag">
          <div className="userpages">
            <div className="sideNavs">
              <SideNav />
            </div>
            <div className="listStores">
              {backendError ? (
                <div className="emptyPage">{backendError}</div>
              ) : logistics.length > 0 ? (
                logistics.map((logistic) => (
                  <EachLogistics
                    {...logistic}
                    key={logistic.officeLocations._id}
                  />
                ))
              ) : (
                <p>
                  No logistics services match your search criteria due to the
                  fact that either you're searching logistics company not yet
                  registered on FarmyApp or we couldn't determine your current
                  location.
                </p>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="mfooter">
        <FMFooter />
      </div>
      <Footer className="footer" />
    </div>
  );
}
