import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { HeadProvider } from "react-head";
import { PaystackButton } from "react-paystack";
import { UserContext } from "../context/UserContext";
import Navbar from "./Navbar";
import Footer from "./Footer";
import profback from "../static/back.jpg";
import { PUBLIC_KEY } from "../config";

const LogisticsDetail = () => {
  const { logisticsSlug } = useParams();
  const { userInfo } = useContext(UserContext);

  const [logisticsData, setLogisticsData] = useState({
    logistics: {
      logisticsName: "",
      email: "",
      username: "",
      phoneNumber: "",
      avatar: "",
      slug: "",
    },
    officeLocations: [],
    vehicles: [],
  });

  const [bookingData, setBookingData] = useState({
    logisticsCompanySlug: logisticsSlug,
    logisticsVehicleId: "",
    pickupLocation: "",
    deliveryLocation: "",
    deliveryType: "withinCity",
    paymentMethod: "card",
    name: "",
    phoneNumber: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBookingData({ ...bookingData, [name]: value });
  };

  // const handleBooking = () => {
  //   try {
  //     const response = axios.post("api/v1/logisticsbooking", bookingData)
  //     return response.data
  //   } catch (error) {
  //       console.error("Error during booking:", error);
  //       throw error;
  //     };
  // };

  const handleBooking = () => {
    return new Promise((resolve, reject) => {
      axios
        .post("api/v1/logisticsbooking", bookingData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          console.log("Booking successful:", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          console.error("Error during booking:", error);
          reject(error);
        });
    });
  };

  const createOrderWithWallet = async () => {
    try {
      const orderData = {
        paymentMethod: "wallet",
        // Add other required order data
        logisticsCompanySlug: bookingData.logisticsCompanySlug,
        logisticsVehicleId: bookingData.logisticsVehicleId,
        pickupLocation: bookingData.pickupLocation,
        deliveryLocation: bookingData.deliveryLocation,
        deliveryType: bookingData.deliveryType,
        name: bookingData.name,
        phoneNumber: bookingData.phoneNumber,
      };

      const response = await axios.post("/api/v1/logisticsbooking", orderData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      console.log("Order created:", response.data);
      window.location = "/myprofile/logisticsOrderList";
      return response.data;
    } catch (error) {
      console.error("Error creating order with wallet:", error);
      // Handle the error from the backend
      if (
        error.response ||
        error.response.data ||
        error.response.data.message
      ) {
        alert(error.response.data.error);
      }
    }
  };
  // console.log(logisticsData.officeLocations);
  useEffect(() => {
    // Use Axios to fetch logistics details
    axios
      .get(`api/v1/logistics/${logisticsSlug}`)
      .then((response) => {
        const { logistics, officeLocations } = response.data;
        setLogisticsData({ logistics, officeLocations });
      })
      .catch((error) => {
        console.error("Error fetching logistics data: ", error);
      });
  }, [logisticsSlug]);

  const publicKey = PUBLIC_KEY;
  const selectedVehicleId = bookingData.logisticsVehicleId; // Get the selected vehicle ID

  const selectedVehicle =
    logisticsData &&
    logisticsData.logistics &&
    logisticsData.logistics.vehicles &&
    logisticsData.logistics.vehicles.find(
      (vehicle) => vehicle._id === selectedVehicleId
    );

  const config = {
    reference: new Date().getTime().toString(),
    email: userInfo.email,
    amount: selectedVehicle ? selectedVehicle.price * 100 : 122200,
    publicKey: publicKey,
  };

  // const onSuccess = (reference) => {
  //   try {
  //     console.log("Payment reference:", reference);
  //     handleBooking()
  //       .then(() => {
  //         window.location = "/myprofile/LogOrderList";
  //       })
  //       .catch((error) => {
  //         console.error("Error handling successful payment:", error);
  //       });
  //   } catch (error) {
  //     console.error("Error handling successful payment:", error);
  //   }
  // };

  const onSuccess = (reference) => {
    try {
      console.log("Payment reference:", reference);
      handleBooking()
        .then(() => {
          window.location = "/myprofile/logisticsOrderList";
        })
        .catch((error) => {
          console.error("Error handling successful payment:", error);
        });
    } catch (error) {
      console.error("Error handling successful payment:", error);
    }
  };

  const onClose = () => {
    console.log("Payment closed");

    // window.location = "/myprofile/MyCart";
  };

  const componentProps = {
    ...config,
    text: "Pay with Bank",
    onSuccess: onSuccess,
    onClose: onClose,
  };

  return (
    <div>
      <Navbar />
      <div className="logDetCont">
        {/* {logisticsData && logisticsData.avatar && ( */}
        <img
          src={logisticsData.avatar ? logisticsData.avatar : profback}
          alt="farmImage"
          className="logimg"
        />
        {/* )} */}
        <div>
          <h3>{logisticsData.logistics.logisticsName}</h3>
          <h5>@{logisticsData.logistics.slug}</h5>
        </div>
      </div>

      {logisticsData && (
        <HeadProvider>
          <meta charSet="utf-8" />
          <title>{logisticsData.logistics.logisticsName}</title>

          {/* Add other necessary meta tags */}
        </HeadProvider>
      )}

      <h5>Office Locations</h5>
      <div className="locationCont">
        {logisticsData &&
          logisticsData.officeLocations.map((officeLocations) => (
            <button
              key={officeLocations.address}
              // onClick={() => handleCategorySelect(category._id)}
              className="categoryButton"
            >
              {officeLocations.address}, {officeLocations.city}
            </button>
          ))}
      </div>

      <div className="logisticsForm">
        <h3 className="sendReceive">Send and Recieve Item</h3>
        <label className="eachInput">
          <div className="label">Logistics Vehicle:</div>
          <select
            name="logisticsVehicleId"
            value={bookingData.logisticsVehicleId}
            onChange={handleInputChange}
            className="inputField"
          >
            <option value="" disabled selected>
              Pick a vehicle
            </option>
            {logisticsData.logistics.vehicles ? (
              logisticsData.logistics.vehicles.map((vehicle) => (
                <option key={vehicle._id} value={vehicle._id}>
                  {vehicle.vehicleType} - {vehicle.price} - {vehicle.plateNum}
                </option>
              ))
            ) : (
              <option value="">No vehicles available</option>
            )}
          </select>
        </label>

        {selectedVehicle && (
          <div>
            <h5>Selected Vehicle Details:</h5>
            <div className="selectedVehicle">
              <img src={selectedVehicle.image} alt="Vehicle" />
              <div>
                <p>Vehicle Type: {selectedVehicle.vehicleType}</p>
                <p>Plate Number: {selectedVehicle.plateNum}</p>
                <p>Price: {selectedVehicle.price}</p>
              </div>
            </div>
          </div>
        )}

        <label className="eachInput">
          <div className="label">Name of pickup Person:</div>
          <input
            type="text"
            name="name"
            value={bookingData.name}
            onChange={handleInputChange}
            className="inputField"
          />
        </label>
        <label className="eachInput">
          <div className="label">Phone Number of pickup person:</div>
          <input
            type="text"
            name="phoneNumber"
            value={bookingData.phoneNumber}
            onChange={handleInputChange}
            className="inputField"
          />
        </label>
        <label className="eachInput">
          <div className="label">Pickup Location:</div>
          <input
            type="text"
            name="pickupLocation"
            value={bookingData.pickupLocation}
            onChange={handleInputChange}
            className="inputField"
          />
        </label>
        <label className="eachInput">
          <div className="label">Delivery Location:</div>
          <input
            type="text"
            name="deliveryLocation"
            value={bookingData.deliveryLocation}
            onChange={handleInputChange}
            className="inputField"
          />
        </label>
        <label className="eachInput">
          <div className="label">Delivery Type:</div>
          <select
            name="deliveryType"
            value={bookingData.deliveryType}
            onChange={handleInputChange}
            className="inputField"
          >
            <option value="withinCity">Within City</option>
            <option value="interstate">Interstate</option>
          </select>
        </label>

        <div className="order-details-btns">
          {/* <PaystackButton {...componentProps} className="order-details-btn2" /> */}
          <button
            className="order-details-btn3"
            onClick={() => createOrderWithWallet()}
          >
            Pay with Wallet
          </button>
          <PaystackButton {...componentProps} className="order-details-btn2" />
        </div>
        {/* <button className="pos_bt log" type="button" onClick={handleBooking}>
          Book Logistics
        </button> */}
        <p className="inst">
          Please before booking this logistics company, confirm they have
          offices close to you pickup and delivery location.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default LogisticsDetail;
