import React from "react";

import "../styles/tanc.css";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";

function TAndC() {
  const navigate = useNavigate();
  return (
    <div className="faqbody">
      <div className="goBack">
        <button onClick={() => navigate(-1)} className="goBack">
          <BiArrowBack /> Go back
        </button>
      </div>
      <div className="faqContent">
        <h1 className="header1">Frequently Asked Questions</h1>
        <div className="eachFaq">
          <h4>What is Farmyapp all about?</h4>
          <p>
            Farmyapp is a multivendor ecommerce site, where buyers buy from
            stores of there choice and have whatever they purchased delivered to them
            within the shortest possible time. More like the shopppers shop.
          </p>
        </div>
        <div className="eachFaq">
          <h4>How can I place an order?</h4>
          <p>
            <ol>
              <li>
                Visit farmyapp.com or download the farmyapp App for iOs or
                Android and Sign in.
              </li>
              <li>
                To buy from store, search for the stores close to you first.
              </li>
              <li>
                Browse the product you need by categories or use the search bar.
              </li>
              <li>Choose the items you are interested in buying.</li>
              <li>Add items to your cart and check out quickly.</li>
              <li>
                Pay for your products securely without we saving your card
                details or directly to our bank account.
              </li>
              <li>
                Your order summary and confirmation will be sent to your
                registered email.
              </li>
            </ol>
          </p>
        </div>
        <div className="eachFaq">
          <h4>How do I make payment?</h4>
          <p>
            You can pay online through the payment system api we are using or
            directly to our bank account.
          </p>
        </div>
        <div className="eachFaq">
          <h4>Can I pay on delivery?</h4>
          <p>
            Not at present time but possibly in future we will arrange for
            that.
          </p>
        </div>
        <div className="eachFaq">
          <h4>Can I return an Item I bought from Farmyapp?</h4>
          <p>Please check our Return & Refund Policy</p>
        </div>
        <div className="eachFaq">
          <h4>How long does order delivery take?</h4>
          <p>
            We deliver between 3-24 hours depending on your location and the
            time you ordered. Please note that not all registered Logistics
            companies run delivery on Sundays, so confirm before booking them on
            Sunday.
          </p>
        </div>
        <div className="eachFaq">
          <h4>Does Farmyapp have an office?</h4>
          <p>
            Yes! We are located at No 1, Agbofieti, Apata, Ibadan but we don't
            do walk-ins. Contact Number: +234706 296 2468
            Email: farmyapp@gmail.com info@farmyapp.com
          </p>
        </div>
        <div className="eachFaq">
          <h4>
            How much is it to put an agricultural product for sale on FarmyApp?
          </h4>
          <p>Putting your product for sale on FarmyApp is Free</p>
        </div>
        <div className="eachFaq">
          <h4>How do I get paid when my product is purchased?</h4>
          <p>
            Immediately the client confirms delivery of the product, you can
            withdraw your money from your FarmyApp wallet to any of your bank
            accounts.
          </p>
        </div>
        <div className="eachFaq">
          <h4>Is their any transaction charges on FarmyApp?</h4>
          <p>Yes, there is a 3% transaction charge on every transaction.</p>
        </div>
        <div className="eachFaq">
          <h4>
            How does the selling of agricultural product work on FarmyApp?
          </h4>
          <p>
            Farmers post their products for sale at least 2 weeks before they are
            ready for sale and in that time, buyers show interest and pay for
            the products with the option of picking them up themselves or have a
            logistics company deliver them.
          </p>
        </div>
      </div>
    </div>
  );
}

export default TAndC;
