import {useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../context/UserContext'
import React from 'react'
import { Link, useParams } from 'react-router-dom';

import { FiEdit } from 'react-icons/fi'
import { ImFilesEmpty } from 'react-icons/im'
import {MdLocationOff} from 'react-icons/md'

// import { UserContext } from '../context/UserContext'
import EachFarmProductadmin from './EachFarmProductadmin'
import EachStoreProductadmin from './EachStoreProductadmin'
import EachCompanyProductAdmin from './EachCompanyProductAdmin'
import EachLocationAdmin from './EachLocationAdmin'
import EachCompanyLocationAdmin from './EachCompLocationAdmin'
import EachOfficeLocationAdmin from './EachOfficeLocationAdmin'
import EachVehicleadmin from './EachVehicleadmin'

import AddStoreLocation from './AddStoreLocation';
import AddCompanyLocation from './AddCompanyLocation';
import AddLogisticsOffice from './AddLogisticsOffice';
import CartPage from '../Pages/CartPage';
import OrderDetail from '../Pages/OrderDetail';
import OrderList from '../Pages/OrderList';
import CompanyOrderList from '../Pages/CompanyOrderList';
import CompanyCartList from '../Pages/CompanyCartList';
import FarmOrderList from '../Pages/FarmOrderList';
import FarmOrder from './farmOrder';
import StoreOrderList from './storeOrder';
import CompanyOrder from './CompanyOrder';
import LogisticsOrders from './LogisticsOrder';
import Dashboard from './Dashboard';
import NotificationComponent from './Notification';
import LogisticsOrderDetail from '../Pages/LogisticsOrderDetail';
import DirectOrder from './DirectOrder';
import LogisticsOrder from './LogisticsOrder';
import LogisticsOrderList from './LogisticsOrderList';

function ProfileProducts({currentContentType}) {
  const {userInfo} = useContext(UserContext);
  const { orderId } = useParams();
  const [showLocationForm, setShowLocationForm] = useState(false);
  const navigate = useNavigate();

  // Use the 'useEffect' hook to check userInfo.username when it changes
  useEffect(() => {
    // console.log(userInfo)
    if (currentContentType === 'user' && userInfo && !userInfo.username) {
      // Redirect to the login page if userInfo.username does not exist
      navigate('/login');
    }
  }, [userInfo, currentContentType, navigate]);

  const handleAddLocationClick = () => {
    setShowLocationForm(true);
  };

  const handleBackToForm = () => {
    setShowLocationForm(false);
  };

  return (
    <div className='profileproduct'>

      {currentContentType === "MyProducts" && userInfo && userInfo.farmName && (
        userInfo.products.length === 0 ? (
          <div>
            <ImFilesEmpty size={200} />
            <h1>No products uploaded yet.</h1>
          </div>
        ) : (
          userInfo.products.map(product => (
            <EachFarmProductadmin {...product} key={product._id} />
          ))
        )
      )}

      {currentContentType === "MyProducts" && userInfo && userInfo.companyName && (
        userInfo.products.length === 0 ? (
          <div>
            <ImFilesEmpty size={200} />
            <h1>No products uploaded yet.</h1>
          </div>
        ) : (
          userInfo.products.map(product => (
            <EachCompanyProductAdmin {...product} key={product._id} />
          ))
        )
      )}

      {currentContentType === 'MyProducts' && userInfo && userInfo.storeName && (
        userInfo.products.length === 0 ? (
          <div>
            <ImFilesEmpty size={200} />
            <h1>No products uploaded yet.</h1>
          </div>
        ) : (
          userInfo.products.map(product => (
            <EachStoreProductadmin {...product} key={`product_${product._id}`} />
          ))
        )
      )}

      {currentContentType === 'MyCart' && userInfo && (
        <CartPage/>
      )}
      {currentContentType === 'OrderDetail' && userInfo && orderId && (
        <OrderDetail orderId={orderId} />
      )}

      {currentContentType === 'OrderList' && userInfo && (
        <OrderList/>
      )}

      {currentContentType === 'CompanyOrderList' && userInfo && (
        <CompanyOrderList/>
      )}

      {currentContentType === 'CompanyCartList' && userInfo && (
        <CompanyCartList/>
      )}

      {currentContentType === 'FarmOrderList' && userInfo && (
        <FarmOrderList/>
      )}

      {currentContentType === 'storeOrder' && userInfo && (
        <StoreOrderList/>
      )}

      {currentContentType === 'CompanyOrder' && userInfo && (
        <CompanyOrder/>
      )}

      {currentContentType === 'farmOrder' && userInfo && (
        <FarmOrder orderId={orderId}/>
      )}

      {currentContentType === 'logisticsOrders' && userInfo && (
        <LogisticsOrders/>
      )}
      {currentContentType === 'logisticsOrderDetail' && userInfo && (
        <LogisticsOrderDetail/>
      )}
      {currentContentType === 'directOrder' && userInfo && (
        <DirectOrder/>
      )}
      {currentContentType === 'logisticsOrderList' && userInfo && (
        <LogisticsOrderList/>
      )}
      {currentContentType === 'logisticsOrder' && userInfo && (
        <LogisticsOrder/>
      )}
      

      {currentContentType === 'Dashboard' && userInfo && (
        <Dashboard/>
      )}

      {currentContentType === 'Notification' && userInfo && (
        <NotificationComponent/>
      )}

      

      {currentContentType === 'AddLocation' && userInfo && userInfo.storeName ? (
        <>
          {showLocationForm ? (
            <div className='locationForm'>
              <AddStoreLocation setShowLocationForm={setShowLocationForm} />
              <button className='lbutton' onClick={handleBackToForm}>Back To Location List</button>
            </div>
          ) : userInfo.storeLocations.length === 0 ? (
            <div className='no-locations'>
              <MdLocationOff size={200} />
              <h1>You've not listed any of your store locations</h1>
              <button className='lbutton' onClick={handleAddLocationClick}>Add Location</button>
            </div>
          ) : (
            <div className='locations'>
              <h5 className='locationHead'>Your Store Locations</h5>
              {userInfo.storeLocations.map(storeLocation => (
                <div className='location' key={`storeLocations_${storeLocation._id}`}>
                  <EachLocationAdmin {...storeLocation} />
                </div>
              ))}
              <button className='lbutton' onClick={handleAddLocationClick}>Add Location</button>
            </div>
          )}
        </>
      ) : null}

      {currentContentType === 'AddLocation' && userInfo && userInfo.companyName ? (
        <>
          {showLocationForm ? (
            <div className='locationForm'>
              <AddCompanyLocation setShowLocationForm={setShowLocationForm} />
              <button className='lbutton' onClick={handleBackToForm}>Back To Location List</button>
            </div>
          ) : userInfo.companyLocations.length === 0 ? (
            <div className='no-locations'>
              <MdLocationOff size={200} />
              <h1>You've not listed any of your company locations</h1>
              <button className='lbutton' onClick={handleAddLocationClick}>Add Location</button>
            </div>
          ) : (
            <div className='locations'>
              <h5 className='locationHead'>Your Company Locations</h5>
              {userInfo.companyLocations.map(companyLocation => (
                <div className='location' key={`companyLocations_${companyLocation._id}`}>
                  <EachCompanyLocationAdmin {...companyLocation} />
                </div>
              ))}
              <button className='lbutton' onClick={handleAddLocationClick}>Add Location</button>
            </div>
          )}
        </>
      ) : null}

      {currentContentType === 'AddLocation' && userInfo && userInfo.logisticsName ? (
        <>
          {showLocationForm ? (
            <div className='locationForm'>
              <AddLogisticsOffice setShowLocationForm={setShowLocationForm} />
              <button className='lbutton' onClick={handleBackToForm}>Back To Location List</button>
            </div>
          ) : userInfo.officeLocations.length === 0 ? (
            <div className='no-locations'>
              <MdLocationOff size={200} />
              <h1>You've not listed any of your office locations</h1>
              <button className='lbutton' onClick={handleAddLocationClick}>Add Location</button>
            </div>
          ) : (
            <div className='locations'>
              <h5 className='locationHead'>Your Store Locations</h5>
              {userInfo.officeLocations.map(storeLocation => (
                <div className='location' key={`storeLocations_${storeLocation._id}`}>
                  <EachOfficeLocationAdmin {...storeLocation} />
                </div>
              ))}
              <button className='lbutton' onClick={handleAddLocationClick}>Add Location</button>
            </div>
          )}
        </>
      ) : null}



      {currentContentType === 'MyVehicles' && userInfo && userInfo.logisticsName && (
        userInfo.vehicles.length === 0 ? (
          <div>
            <ImFilesEmpty size={200} />
            <h1>You have not uploaded any vehicles yet.</h1>
          </div>
        ) : (
          userInfo.vehicles.map(vehicle => (
            <EachVehicleadmin {...vehicle} key={vehicle._id} />
          ))
        )
      )}

      {currentContentType === 'user' && userInfo && userInfo.name && (
        <p>fdghjkjhg vghjh</p>
      )}


        

      {userInfo && userInfo.farmName && (
        <Link to='/uploadfarmproduct' className='uploadproduct'>
          <FiEdit size={30} />
          <p>Upload Product</p>
        </Link>
      )} 
      {userInfo && userInfo.storeName && (
        <Link to='/uploadstoreproduct' className='uploadproduct'>
          <FiEdit size={30} />
          <p>Upload Product</p>
        </Link>
      )}
      {userInfo && userInfo.companyName && (
        <Link to='/uploadcompanyproduct' className='uploadproduct'>
          <FiEdit size={30} />
          <p>Upload Product</p>
        </Link>
      )}
      {userInfo && userInfo.logisticsName && (
        <Link to='/uploadlogisticsvehicle' className='uploadproduct'>
          <FiEdit size={30} />
          <p>Add new vehicle</p>
        </Link>
      )}
    </div>
  )
}

export default ProfileProducts;
