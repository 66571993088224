import { Link } from "react-router-dom";
import { TbTruckDelivery } from "react-icons/tb";

function EachLogistics({
  _id,
  avatar,
  slug,
  logisticsName,
  distanceMeters,
  storeName,
  username
}) {
  return (
    <Link className="eachStore" to={`/logistics/${slug}`}>
      <div>
        {avatar ? (
          <img src={avatar} alt={storeName} className="storeImage" />
        ) : (
          <TbTruckDelivery size="100" />
        )}
      </div>
      <div className="storeData">
        <h3>{logisticsName}</h3>
        <p>{username}</p>
        <p>{slug}</p>
      </div>
      <p className="storeDistance">{Math.floor(distanceMeters)}meters away</p>
    </Link>
  );
}

export default EachLogistics;
