import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";

function RecoverAccountC() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  toastr.options = {
    closeButton: true,
    progressBar: true,
    positionClass: "toast-top-right",
    timeOut: 5000,
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
  };

  const displayError = (err) => {
    toastr.warning(err, "Account recovery failed");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await axios({
        url: "api/v1/user/recoveraccount",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: JSON.stringify({ email }),
      });

      navigate("/signin");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        displayError("Invalid email. Are you sure you have an account on FarmyApp?");
      } else {
        displayError("An error occurred. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="signufform">
      <div className="loading-overlay" style={{ display: isLoading ? "block" : "none" }}>
        <ClipLoader css={override} size={150} color={"#123abc"} loading={isLoading} />
        <p>Loading...</p>
      </div>
      <div className="sign_form" style={{ filter: isLoading ? "blur(3px)" : "none" }}>
        <form className="post_sign" onSubmit={handleSubmit}>
          <div className="waitlist_post">
            <label className="form_label">Email</label>
            <input
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className="form_input form_inp"
              placeholder={"Your email"}
            />
          </div>
          <button className="sign_bt" disabled={isLoading}>
            {isLoading ? "Submitting..." : "Submit"}
          </button>
        </form>
        <div className="already1">
          Already recovered your account?{" "}
          <Link to="/login" className="signlink">
            Sign In
          </Link>
        </div>
      </div>
    </div>
  );
}

export default RecoverAccountC;
