import React, { useContext, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

// import { UserContext } from '../context/UserContext';

// import Cookies from 'universal-cookie';

import { css } from '@emotion/react'; // Import the loader
import { ClipLoader } from 'react-spinners'; // Import the loader

function ResetPasswordC() {
  const navigate = useNavigate();

  // const cookies = new Cookies();

  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  // const { setUserInfo } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  toastr.options = {
    closeButton: true,
    progressBar: true,
    positionClass: 'toast-top-right',
    timeOut: 5000,
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
  };

  const displayError = (err) => {
    toastr.warning(err, 'Password change Failed');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await axios({
        url: 'api/v1/user/resetpassword',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({ email, verificationCode, newPassword }),
      });
  
      // // Store the token in localStorage
      // localStorage.setItem('token', loginResponse.data.token);

      // const profileResponse = await axios({
      //   url: 'api/v1/user/profile',
      //   method: 'GET',
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem('token')}`,
      //   },
      // });

      // const userInfoData = profileResponse.data;
      // setUserInfo(userInfoData);
      // // console.log(userInfoData);
      navigate('/signin');
    } catch (error) {
      if (error.response && error.response.status === 401) {
        displayError("Invalid email or verification token. Are you sure you have an account on FarmyApp?.");
      } else {
        displayError("An error occurred. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="signufform">
      <div className="loading-overlay" style={{ display: isLoading ? 'block' : 'none' }}>
        <ClipLoader css={override} size={150} color={'#123abc'} loading={isLoading} />
        <p>Loading...</p>
      </div>
      <div className="sign_form" style={{ filter: isLoading ? 'blur(3px)' : 'none' }}>
        <form className="post_sign" onSubmit={handleSubmit}>
          <div className="waitlist_post">
            <label className="form_label">Email</label>
            <input
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className="form_input form_inp"
              placeholder={'Your email'}
            />
          </div>
          <div className="waitlist_post">
            <label className="form_label">Verification code</label>
            <input
              type="text"
              onChange={(e) => setVerificationCode(e.target.value)}
              value={verificationCode}
              className="form_input form_inp"
              placeholder={'The verification code sent to your mail'}
            />
          </div>
          <div className="waitlist_post">
            <label className="form_label">New Password</label>
            <div className="password-input-container">
              <input
                type={showPassword ? 'text' : 'password'}
                onChange={(e) => setNewPassword(e.target.value)}
                value={newPassword}
                className="form_input form_inp"
                placeholder={'Enter your new password here'}
              />
              <button className='showbutton' type="button" onClick={handleTogglePassword}>
                {showPassword ? 'Hide' : 'Show'}
              </button>
            </div>
          </div>

          <button className='sign_bt' disabled={isLoading}>
            {isLoading ? 'Submitting...' : 'Submit'}
          </button>
        </form>
        <div className="already1">
          Remember your password now?{' '}
          <Link to="/signin" className="signalt">
            Sign in
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordC
;
