import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";
import { io } from "socket.io-client";
import { Link } from "react-router-dom";
import axios from "axios";
import { format } from "timeago.js";
import { I0_LINK } from "../config";

import "../styles/notification.css";

const NotificationComponent = () => {
  const { userInfo } = useContext(UserContext);
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    // Fetch notifications from the server
    const fetchNotifications = async () => {
      try {
        const response = await axios({
          url: "/api/v1/notifications/user",
          method: "GET",
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }); // Replace with your API endpoint
        const { notifications, unreadCount } = response.data;
        setNotifications(notifications);
        setUnreadCount(unreadCount);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
    const io_link = I0_LINK;
    const socket = io(io_link);

    // Listen for incoming notifications
    socket.on(`notification_${userInfo._id}`, (notification) => {
      // Update the notifications state with the new notification
      setNotifications((prevNotifications) => [
        notification,
        ...prevNotifications,
      ]);

      // Update the unread count
      setUnreadCount((prevCount) => prevCount + 1);
    });

    // Clean up event listener on unmount
    return () => {
      socket.off("notification_USER_ID");
    };
  }, [userInfo._id]);

  return (
    <div className="dashboard">
      <h1> ({unreadCount}) Notifications</h1>
      <ul className="ulContainer">
        {notifications.map((notification) => (
          <Link className="notificationLinks" to={notification.link}>
            <li key={notification._id}>
              <div className="notification-content">
                <h2 className="notification-message">{notification.message}</h2>
                <p className="notification-date">
                  {format(notification.createdAt)}
                </p>
              </div>
            </li>
            <hr class="solid"></hr>
          </Link>
        ))}
      </ul>
    </div>
  );
};

export default NotificationComponent;
