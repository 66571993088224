import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate, Link } from "react-router-dom";
import { HeadProvider, Meta } from "react-head";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { FaStar, FaRegStar } from "react-icons/fa";
import { BsCart3 } from "react-icons/bs";
// import { BiLogoInstagramAlt } from "react-icons/bi";
// import { AiFillTwitterCircle, AiFillLinkedin } from "react-icons/ai";
// import { IoLogoWhatsapp } from "react-icons/io";
import { UserContext } from "../context/UserContext";

import EachFarmProduct from "../Components/EachFarmProduct";
import "../styles/productDetail.css";

const FarmUnavailable = () => {
  const navigate = useNavigate();
  const { userInfo } = useContext(UserContext);
  const [product, setProduct] = useState(null);
  const [relatedProducts, setRelatedProduct] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [cart, setCart] = useState({
    items: [],
    totalAmount: 0,
    isLoading: true,
  });
  // const [quantity, setQuantity] = useState(1);

  const { id } = useParams();

  const onTop = () => {
    window.scrollTo(0, 0);
  };

  // const isAuthenticated = () => {
  //   const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  //   return !!userInfo && !!userInfo?.username;
  // };

  useEffect(() => {
    onTop();
    const fetchProductDetail = async () => {
      try {
        const response = await axios.get(`/api/v1/unavailable/${id}`, {
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const productData = response.data.product;
        // console.log(productData);
        setProduct(productData);
        setRelatedProduct(response.data.relatedProducts);
        setSelectedImage(productData.images[0]);
      } catch (error) {
        console.error("Error fetching product detail:", error);
      }
    };

    fetchProductDetail();
  }, [id]);

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const response = await axios.get("/api/v1/farmcart", {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setCart({
          items: response.data.cart.items,
          totalAmount: response.data.cart.totalAmount,
          isLoading: false,
        });
      } catch (error) {
        console.error("Error fetching cart items:", error);
        setCart({ items: [], totalAmount: 0, isLoading: false });
      }
    };

    fetchCartItems();
  }, []);

  // const shareProduct = (platform) => {
  //   const url = window.location.href;
  //   const title = product.productName;
  //   const description = product.productDescription;

  //   switch (platform) {
  //     case "facebook":
  //       window.open(
  //         `https://www.facebook.com/sharer/sharer.php?u=${url}`,
  //         "_blank"
  //       );
  //       break;
  //     case "instagram":
  //       // Implement Instagram sharing logic here
  //       break;
  //     case "twitter":
  //       const tweetText = `${title} - ${description}  ${url}`;
  //       window.open(
  //         `https://twitter.com/intent/tweet?text=${tweetText}`,
  //         "_blank"
  //       );
  //       break;
  //     case "whatsapp":
  //       const whatsappText = `${title} - ${description} ${url}`;
  //       window.open(
  //         `https://api.whatsapp.com/send?text=${whatsappText}`,
  //         "_blank"
  //       );
  //       break;
  //     case "linkedin":
  //       // Implement LinkedIn sharing logic here
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  // const handleAddToCart = async () => {
  //   try {
  //     if (!userInfo?._id) {
  //       return navigate("/signin");
  //     }
  //     if (!isAuthenticated()) {
  //       return <Navigate to="/signin" />;
  //     }

  //     const response = await axios.post(
  //       "/api/v1/farmcart",
  //       {
  //         product: product._id,
  //         quantity,
  //       },
  //       {
  //         headers: {
  //           // "Content-Type": "multipart/form-data",
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       }
  //     );

  //     setCart(response.data.cart);

  //     const cartItemsResponse = await axios.get("/api/v1/farmcart", {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //     });
  //     setCart(cartItemsResponse.data.cart);
  //   } catch (error) {
  //     console.error("Error adding to cart:", error);
  //   }
  // };

  const handleChatWithFarm = async () => {
    try {
      if (!userInfo?._id) {
        return navigate("/signin");
      }
      const userId = userInfo._id;
      const farmId = product?.userId;
      // console.log(userId, farmId);
      const response = await axios.post(
        "/api/v1/chat/create",
        {
          participants: [userId, farmId],
        },
        {
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const chatId = response.data.chat._id;
      if (chatId) {
        navigate(`/chat/${chatId}`);
      }
    } catch (error) {
      console.error("Error creating chat:", error);
    }
  };

  // const handleIncrement = () => {
  //   setQuantity(quantity + 1);
  // };

  // const handleDecrement = () => {
  //   if (quantity > 1) {
  //     setQuantity(quantity - 1);
  //   }
  // };

  const rating =
    product && product.numReviews ? (product.numReviews / 5).toFixed(1) : "0.0";

  let number = product && product.perUnitPrice;
  let x = Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(
    number
  );

  return (
    <div>
      <Navbar />
      <HeadProvider>
        <Meta charSet="utf-8" />
        <title>{product?.productName}</title>
        <Meta
          name="description"
          content={`Welcome to ${product?.productName}`}
        />
        <Meta property="og:title" content={product?.productName} />
        <Meta property="og:image" content={product?.images?.[0]} />
        {/* Add other necessary meta tags */}
      </HeadProvider>
      <div className="detailPageContainer">
        <div className="detailPage">
          <div className="detailContainer">
            {product && (
              <div className="wholeProductCentent">
                <div className="topContCont">
                  <div className="topContainer">
                    <h1 className="h1NoPad">{product.productName}</h1>
                    <span className="topUnder">
                      <span style={{ color: "black" }}>Brand : </span>
                      {product.userId.farmName}
                    </span>{" "}
                    |
                    <span className="topUnder">
                      <span style={{ color: "black" }}>Rating : </span>
                      <span className="">
                        {Array.from({ length: 5 }).map((_, index) => (
                          <span key={index}>
                            {index < Math.floor(rating) ? (
                              <FaStar />
                            ) : (
                              <FaRegStar />
                            )}
                          </span>
                        ))}
                        <span className="topUnder"> {rating} stars</span>
                      </span>
                    </span>
                  </div>
                  <div>
                    <button className="chatButton" onClick={handleChatWithFarm}>
                      Chat with buyer
                    </button>
                    {/* <button className="chatButton"> Chat with Farmer</button> */}
                  </div>
                </div>

                <div className="productDetail">
                  <div>
                    <div>
                      <img
                        src={selectedImage}
                        alt={product.productName}
                        className="mainImage"
                      />
                    </div>
                    <div>
                      {product.images.map((image, index) => (
                        <img
                          key={index}
                          src={image}
                          alt={product.productName}
                          onClick={() => handleImageClick(image)}
                          className="otherImages"
                        />
                      ))}
                    </div>
                  </div>
                  <div className="productBody">
                    <h3>&#x20A6;{x} per {product.measuringScale}</h3>
                    {/* <p className="productStock">in-Stock</p> */}

                    <p className="productDescription">
                      {product.productDescription}
                    </p>
                    <div className="cartControl">
                      {/* <div className="quantity-control">
                        <button onClick={handleDecrement}> - </button>
                        <span>{quantity} {product.measuringScale}</span>
                        <button onClick={handleIncrement}>+</button>
                      </div> */}
                      
                      <Link className="cartButton" to="/uploadfarmproduct">
                        Upload Product
                      </Link>
                    </div>
                    <div></div>
                  </div>
                </div>
                {/* <div className="shareButtons">
                  <button className="report">Report Abuse</button>
                  <div className="shareB">
                    <span>Share: </span>
                    <button
                      className="eachButton"
                      onClick={() => shareProduct("facebook")}
                    >
                      <BsFacebook size={18} />
                    </button>
                    <button
                      className="eachButton"
                      onClick={() => shareProduct("instagram")}
                    >
                      <BiLogoInstagramAlt size={20} />
                    </button>
                    <button
                      className="eachButton"
                      onClick={() => shareProduct("twitter")}
                    >
                      <AiFillTwitterCircle size={20} />
                    </button>
                    <button
                      className="eachButton"
                      onClick={() => shareProduct("whatsapp")}
                    >
                      <IoLogoWhatsapp size={20} />
                    </button>
                    <button
                      className="eachButton"
                      onClick={() => shareProduct("linkedin")}
                    >
                      <AiFillLinkedin size={20} />
                    </button>
                  </div>
                </div> */}
              </div>
            )}
          </div>
          <div className="cart-section">
            <h3>Cart Items</h3>
            {cart.isLoading ? (
              <p>Cart is loading...</p>
            ) : cart.items.length > 0 ? (
              <div className="detailCart">
                <table>
                  <tbody>
                    {cart.items.map((item, index) => (
                      <tr key={index}>
                        <td className="detailCartEach">
                          <img
                            src={item.product?.images?.[0]}
                            alt={item.product?.productName}
                            className="otherImages"
                          />
                        </td>
                        <td className="detailCartEach">
                          {item.product?.productName}
                        </td>
                        <td className="detailCartEach">
                          <p className="deatilCartCunt">
                            {item.quantity} {item.product?.measuringScale}
                          </p>
                        </td>
                        <td className="detailCartEach">
                          <h5>
                            &#x20A6;
                            {parseFloat(
                              item.quantity * item.product?.perUnitPrice
                            ).toLocaleString("en")}
                          </h5>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="detailCartTotal">
                  <h3>
                    Current Total: &#x20A6;
                    {parseFloat(cart.totalAmount).toLocaleString("en")}
                  </h3>
                </div>
              </div>
            ) : (
              <div className="empty-cart">
                <h3>Your cart is empty</h3>
                <BsCart3 size={80} />
              </div>
            )}
          </div>
        </div>
        {relatedProducts && relatedProducts.length > 0 && (
          <div>
            <h3>Related Products</h3>
            <div className="relatedProduct">
              {relatedProducts.map((relatedProduct) => (
                <EachFarmProduct {...relatedProduct} key={relatedProduct._id} />
              ))}
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default FarmUnavailable;
