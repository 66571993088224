import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { PaystackButton } from "react-paystack";
import { UserContext } from "../context/UserContext";
// import { useNavigate } from "react-router-dom";
import "../styles/cart.css";
import { BsCart4 } from "react-icons/bs";
import { FaGreaterThan, FaLessThan } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { PUBLIC_KEY } from "../config";

const FarmCart = ({ farmCartItems }) => {
  const [farmCart, setFarmCart] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { userInfo } = useContext(UserContext);
  const [totalAmount, setTotalAmount] = useState("");
  // const navigate = useNavigate();
  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const response = await axios.get("/api/v1/farmcart", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },});
        setFarmCart(response.data.cart.items); // Set to items here
        setDataLoaded(true);
        setTotalAmount(response.data.totalAmount);
      } catch (error) {
        console.error("Error fetching farm cart data:", error);
      }
    };

    fetchCartItems();
  }, []);

  const createOrderWithWallet = async () => {
    try {
      const orderData = {
        paymentMethod: "wallet",
      };

      const response = await axios.post("/api/v1/farmorder/create", orderData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

      window.location = "/myprofile/FarmOrderList";
      return response.data;
    } catch (error) {
      if (
        error.response ||
        error.response.data ||
        error.response.data.message
      ) {
        alert(error.response.data.error);
      }
    }
  };

  const config = {
    reference: new Date().getTime().toString(),
    email: userInfo.email, // Replace with the user's email
    amount: totalAmount * 100, // Paystack uses amount in kobo (1 NGN = 100 kobo)
    publicKey: PUBLIC_KEY,
  };

  const onSuccess = (reference) => {
    try {
      // console.log("Payment reference:", reference);
      // Call createOrder function and handle its response
      createOrder()
        .then(() => {
          // console.log("Order created:", orderData);
          window.location = "/myprofile/FarmOrderList";
        })
        .catch((error) => {
          console.error("Error handling successful payment:", error);
          // Handle the error, e.g., showing a message to the user
        });
    } catch (error) {
      console.error("Error handling successful payment:", error);
      // Handle the error, e.g., showing a message to the user
    }
  };

  const onClose = () => {
    console.log("Payment closed");

    window.location = "/myprofile/MyCart";
  };

  const componentProps = {
    ...config,
    text: "Pay with Bank",
    onSuccess: onSuccess,
    onClose: onClose,
  };

  const createOrder = async () => {
    try {
      const orderData = {};

      const response = await axios.post("/api/v1/farmorder/create", orderData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      // console.log("Order created:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error creating order:", error);
      throw error;
    }
  };

  const handleRemoveItem = async (itemId) => {
    // Remove the item from the cart
    try {
      await axios({
        url: "/api/v1/farmcart",
        // withCredentials: true,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        method: "DELETE",
        headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: JSON.stringify({ product: itemId }),
      }).then((response) => {
        setFarmCart(response.data.cart.items);
      });
    } catch (error) {
      console.log(error.response);
    }
  };

  const handleQuantityChange = (itemId, newQuantity) => {
    // Update the quantity of the item
    axios({
      url: "/api/v1/farmcart",
      method: "PUT",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      data: { product: itemId, quantity: newQuantity },
    })
      .then((response) => {
        setFarmCart(response.data.cart.items);
      })
      .catch((error) => {
        console.error("Error updating item quantity in the farm cart:", error);
      });
  };

  // const handleLogisticsBooking = (itemId) => {
  //   // Implement logic to book logistics for an item
  //   // You can open a modal or a logistics booking form
  // };

  return (
    <div>
      {dataLoaded ? (
        farmCart === 0 ? (
          <div>
            <BsCart4 size={200} />
            <p>You've not added any farm produce to your cart.</p>
          </div>
        ) : (
          <div className="detailCart1">
            <table className="cartContainer">
              <thead>
                <tr>
                  <th colSpan="2" className="detailCartEach1">
                    Product Details
                  </th>
                  <th className="detailCartEach">Quantity</th>
                  <th className="detailCartEach">Subtotal</th>
                  <th className="detailCartEach">Action</th>
                </tr>
              </thead>
              <tbody>
                {farmCart.map((item) => (
                  <tr key={item._id}>
                    <td className="detailCartEach1">
                      <img
                        src={item.product.images[0]}
                        alt={item.product.productName}
                        className="otherImages"
                      />
                    </td>
                    <td className="detailCartEach1">
                      <h4>{item.product.productName}</h4>
                    </td>
                    <td className="CartQuantityCont">
                      <button
                        onClick={() =>
                          handleQuantityChange(
                            item.product._id,
                            item.quantity - 1
                          )
                        }
                      >
                        <FaLessThan />
                      </button>
                      <h3>{item.quantity}</h3>
                      <button
                        onClick={() =>
                          handleQuantityChange(
                            item.product._id,
                            item.quantity + 1
                          )
                        }
                      >
                        <FaGreaterThan />
                      </button>
                    </td>
                    <td className="detailCartEach1">
                      &#x20A6;{item.quantity * item.product.perUnitPrice}
                    </td>
                    <td className="detailCartEach1">
                      <div onClick={() => handleRemoveItem(item.product._id)}>
                        <MdDelete size="20" className="cartBasket" />
                        Remove item
                      </div>
                      {/* <button
                        onClick={() => handleLogisticsBooking(item._id)}
                        className="cartLog"
                      >
                        Book Logistics
                      </button> */}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan="3" className="detailCartEach">
                    Total
                  </td>
                  <td colSpan="2" className="detailCartEach">
                    &#x20A6; {totalAmount}
                    {/* {farmCart.reduce(
                      (total, item) =>
                        total + item.quantity * item.product.perUnitPrice,
                      0
                    )} */}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="order-details-btns">
              {/* <PaystackButton {...componentProps} className="order-details-btn2" /> */}
              <button
                className="order-details-btn3"
                onClick={() => createOrderWithWallet()}
              >
                Pay with Wallet
              </button>
              <PaystackButton
                {...componentProps}
                className="order-details-btn2"
              />
            </div>
          </div>
        )
      ) : (
        <p>Loading cart data...</p>
      )}
    </div>
  );
};

export default FarmCart;
