import { Link } from "react-router-dom";
// import { CiShoppingBasket } from "react-icons/ci";
// import { FaStar, FaRegStar } from "react-icons/fa";
import { FaStoreAlt } from "react-icons/fa";

function EachCompany({
  _id,
  avatar,
  city,
  slug,
  companyLocations,
  companyName,
  username
}) {
  // Calculate the rating based on numReviews
  //   const rating = numReviews > 0 ? (numReviews / 5).toFixed(1) : "0.0";

  return (
    <Link className="eachStore" to={`/company/${slug}`}>
      <div>
      {avatar ? (
        <img src={avatar} alt={companyName} className="storeImage" />
      ) : (
        <FaStoreAlt size="100" />
      )}
      </div>
      <div className="storeData">
        <h3>{companyName}</h3>
        <p>{city}</p>
        <p>{username}</p>
        <p>{companyLocations.city}</p>
        <p>{companyLocations.address}</p>
      </div>
      <p className="storeDistance">
  {Math.floor(companyLocations.distanceMeters).toLocaleString()} meters away
</p>
      {/* <p className="storeDistance">{Math.floor(storeLocations.distanceMeters)} meters away</p> */}
    </Link>
  );
}

export default EachCompany;
