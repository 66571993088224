import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../styles/Orderlist.css";
import { AiFillCaretDown } from "react-icons/ai";
import { BiArrowToLeft } from "react-icons/bi";
import { BiArrowToRight } from "react-icons/bi";

export default function LogisticsOrderList() {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get("/api/v1/logisticsbooking", {
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setOrders(response.data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, []);
  return (
    <div className="order-list-view-container">
      <h2> Direct Logistics Order List</h2>
      <div className="order-list-view-container-nav">
        <h3>All Orders</h3>
        <h3> Completed</h3>
        <h3>Continuing</h3>
        <h3>Restitute</h3>
        <h3>Canceled</h3>
      </div>
      <div className="order-list-view-container-search-bar">
        <div className="order-list-view-container-search-bar-input">
          <input
            placeholder="Search for order id, store, order status or something..."
            className="order-list-view-container-search-bar-input-field"
          />
        </div>
        <div className="order-list-view-container-search-bar-filter">
          <i className="bx bx-chevron-down"></i>
          <h3>Filter</h3>
        </div>
        <div className="order-list-view-container-search-bar-export">
          <h3>Export</h3>
        </div>
      </div>
      <div className="order-list-view-container-table">
        <table className="order-list-table">
          <thead>
            <tr className="order-list-view-container-table-head">
              <th className="order-list-view-container-table-head-order-id">
                Order ID
              </th>
              <th>User Type</th>
              <th>Pickup Location</th>
              <th>Delivery Location</th>
              <th>Status</th>
              <th>Delivery Type</th>
              <th>Updated At</th>
            </tr>
          </thead>

          <tbody>
            {orders.map((order) => (
              <tr key={order._id}>
                <td className="order-list-view-container-table-body-data-store">
                  <Link to={`/logisticsorder/${order._id}`}>{order._id}</Link>
                </td>
                <td>{order.userType}</td>
                <td>{order.pickupLocation}</td>
                <td>{order.deliveryLocation}</td>
                <td>{order.status}</td>
                <td className="order-list-view-container-table-body-data-item-count">
                  {order.deliveryType}
                </td>
                <td>{order.updatedAt}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="order-list-navigation">
        <p>1-12 of 1279</p>
        <div className="order-list-navigation-btn">
          <p>you are on page</p>
          <div className="order-list-navigation-btn-icon">
            <p>1</p>
            <AiFillCaretDown />
          </div>
          <div className="order-list-navigation-btn-icon2">
            <BiArrowToLeft />
          </div>
          <div className="order-list-navigation-btn-icon2">
            <BiArrowToRight />
          </div>
        </div>
      </div>

      <div className="mobile-view">
        {orders.map((order) => (
          <div className="order-list-mobile-reference" key={order._id}>
            <h4 className="order-list-mobile-reference-id">
              <Link to={`/logisticsorder/${order._id}`}>{order._id}</Link>
            </h4>
            <h4 className="order-list-mobile-reference-date">
              {order.userType}
            </h4>
            <h4 className="order-list-mobile-reference-label">
              {order.pickupLocation}
            </h4>
            <h4>{order.deliveryLocation}</h4>
            <h4 className="order-list-mobile-reference-label">
              {order.status}
            </h4>
            <h4>{order.deliveryType}</h4>
            <h4 className="order-list-mobile-reference-status">
              {order.updatedAt}
            </h4>
          </div>
        ))}
      </div>
    </div>
  );
}
