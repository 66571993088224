import React from "react";

import "../styles/tanc.css";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi"

function TAndC() {
    const navigate = useNavigate()
  return (
    <div className="tcbody">
      <div className="goBack">
      <button onClick={() => navigate(-1)} className="goBack"> 

        <BiArrowBack/> Go back
      </button>
      </div>
      <h1 className="header1">FarmyApp Terms of Use</h1>
      <p>
        This document is an electronic record in terms of the National
        Information Technology Development Agency Act, 2007 and rules thereunder
        as applicable, as well as the amended provisions pertaining to
        electronic records in various statutes as amended by the National
        Information Technology Development Agency Act, 2007. This electronic
        record is generated by a computer system and does not require any
        physical or digital signatures.
      </p>
      <p>
        Your use of the www.Farmyapp.com website and tools is governed by the
        following terms and conditions as applicable to Farmyapp.com. If you
        transact on Farmyapp.com, you shall be subject to the policies that are
        applicable to the website for such transactions. By mere use of the
        website, you shall be contracting with Phi Agribusiness Agritech
        Development Ent., a company incorporated under the Companies and Allied
        Matters Act, 2004 with head office at No. 1 Agbofieti, off Apata Road,
        Ibadan, Nigeria, and these terms and conditions constitute your binding
        obligations.
      </p>
      <p>
        For the purpose of these terms of use, wherever the context so requires,
        ‘You’ shall mean any natural or legal person who has agreed to become a
        member of the Website by providing Registration Data while registering
        on the Website as a Registered User.
      </p>
      <p>
        When you use any of the services provided by Farmyapp.com, including but
        not limited to (e.g., Purchases, Customer Reviews), you will be subject
        to the rules, guidelines, policies, terms, and conditions applicable to
        such service, and they shall be deemed to be incorporated into this
        Terms of Use and shall be considered part and parcel of this Terms of
        Use. Farmyapp.com reserves the right, at its sole discretion, to change,
        modify, add, or remove portions of these Terms of Use at any time. It is
        your responsibility to check these Terms of Use periodically for
        changes. Your continued use of the Site following the posting of changes
        will mean that you accept and agree to the changes. As long as you
        comply with these Terms of Use, Phi Agribusiness Agritech Development
        Ent. grants you a personal, non-exclusive, non-transferable, limited
        privilege to enter and use the site.
      </p>
      <p>
        ACCESSING, BROWSING, OR OTHERWISE USING THE SITE INDICATES YOUR
        AGREEMENT TO ALL THE TERMS AND CONDITIONS IN THIS AGREEMENT, SO PLEASE
        READ THIS AGREEMENT CAREFULLY BEFORE PROCEEDING.
      </p>

      <h3>Membership Eligibility</h3>
      <p>
        Use of the Farmyapp website is available only to persons who can form
        legally binding contracts under Nigerian governing laws. If you are a
        minor, i.e., under the age of 18, you shall not register as a member of
        Farmyapp.com and shall not transact on or use the Farmyapp.com website.
        As a minor, if you wish to use or transact on Farmyapp.com, such use or
        transaction may be made by your legal guardian or parents who have
        registered as users of Farmyapp.com. Farmyapp.com reserves the right to
        terminate your membership and refuse to provide you with access to
        Farmyapp.com if it is brought to Phi Agribusiness Agritech Development
        Ent.'s notice or if it is discovered that you are under the age of 18.
      </p>
      <h3>Your Account and Registration Obligations</h3>
      <p>
        If you use Farmyapp.com, you shall be responsible for maintaining the
        confidentiality of your User ID and Password, and you shall be
        responsible for all activities that occur under your User ID and
        Password. You agree that if you provide any information that is untrue,
        inaccurate, not current, or incomplete that Farmyapp.com has reasonable
        grounds to suspect that such information is untrue, inaccurate, not
        current, or incomplete, or is not in accordance with this Terms of Use,
        Farmyapp.com has the right to indefinitely suspend, terminate, or block
        access to your membership with Farmyapp.com and refuse to provide you
        with access to the website.
      </p>
      <h3>Communications</h3>
      <p>
        When You use the Website or send emails or other data, information, or
        communication to Farmyapp.com, You agree and understand that You are
        communicating with Farmyapp.com through electronic records, and You
        consent to receive communications via electronic records from
        Farmyapp.com periodically. Farmyapp.com may communicate with You by
        email or by such other mode of communication, electronic or otherwise.
      </p>
      <h3>Charges</h3>
      <p>
        Membership on Farmyapp.com is free. Farmyapp.com does not charge any fee
        for browsing and buying on Farmyapp.com. However, Farmyapp.com reserves
        the right to charge a fee and change its policies from time to time. In
        particular, Farmyapp.com may at its sole discretion introduce new
        services and modify some or all of the existing services offered on
        Farmyapp.com. In such an event Farmyapp.com reserves, without notice to
        You, the right to introduce fees for the new services offered or
        amend/introduce fees for existing services, as the case may be. Changes
        to the Fee and related policies shall automatically become effective
        immediately once implemented on Farmyapp.com. Unless otherwise
        stated,all fees shall be quoted in Nigerian Naira.You shall be solely
        responsible for compliance of all applicable laws including those in
        Nigeria for making payments to Farmyapp.com.
      </p>
      <h3>Use of Farmyapp.com Website</h3>
      <p>
        You agree, undertake, and confirm that your use of Farmyapp.com shall be
        strictly governed by the following binding principles:
      </p>
      <p>
        You shall not host, display, upload, modify, publish, transmit, update,
        or share any information that:
      </p>
      <ul>
        <li>
          Belongs to another person and to which you do not have any right.
        </li>
        <li>
          Is grossly harmful, harassing, blasphemous, defamatory, obscene,
          pornographic, paedophilic, libellous, invasive of another's privacy,
          hateful, or racially or ethnically objectionable, disparaging,
          relating to or encouraging money laundering or gambling, or otherwise
          unlawful in any manner whatever; or unlawfully threatening or
          unlawfully harassing, including but not limited to "indecent
          representation of women" or misleading in any way.
        </li>
        <li>
          Is patently offensive to the online community, such as sexually
          explicit content or content that promotes obscenity, pedophilia,
          racism, bigotry, hatred, or physical harm of any kind against any
          group or individual;
        </li>
        <li>Harasses or advocates harassment of another person;</li>
        <li>
          Involves the transmission of "junk mail," "chain letters," unsolicited
          mass mailing, or "spamming";
        </li>
        <li>
          Promotes illegal activities or conduct that is abusive, threatening,
          obscene, defamatory, or libellous;
        </li>
        <li>
          Infringes upon or violates any third party's rights (including but not
          limited to intellectual property rights, rights of privacy (including
          without limitation, unauthorized disclosure of a person's name, email
          address, physical address, or phone number), or rights of publicity;
        </li>
        <li>
          Promotes an illegal or unauthorized copy of another person's
          copyrighted work (see "Copyright complaint" below for instructions on
          how to lodge a complaint about uploaded copyrighted material), such as
          providing pirated computer programs or links to them, providing
          information to circumvent manufacture-installed copy-protect devices,
          or providing pirated music or links to pirated music files; Contains
          restricted or password-only access pages, hidden pages, or images
          (those not linked to or from another accessible page);
        </li>
        <li>
          Provides material that exploits people in a sexual, violent, or
          otherwise inappropriate manner or solicits personal information from
          anyone;
        </li>
        <li>
          Provides instructional information about illegal activities such as
          making or buying illegal weapons, violating someone's privacy, or
          providing or creating computer viruses;
        </li>
        <li>
          Contains video, photographs, or images of another person age 18 or
          older without his or her express written consent and permission or
          those of any minor (regardless of whether you have consent from the
          minor or his or her legal guardian).
        </li>

        <li>
          Tries to gain unauthorized access or exceeds the scope of authorized
          access (as defined herein and in other applicable Codes of Conduct or
          End User Access and Licence Agreements) to the site or to profiles,
          blogs, communities, account information, bulletins, friend requests,
          or other areas of the site, or solicits passwords or personal
          identifying information for commercial or unlawful purposes from other
          users;
        </li>
        <li>
          Engages in commercial activities and/or sales without Farmyapp's prior
          written consent, such as contests, sweepstakes, barter, advertising,
          pyramid schemes, or the buying or selling of "virtual" items related
          to the Sites. Throughout these Terms of Use, "prior written consent"
          means a communication coming from the Farmyapp Legal Department,
          specifically in response to your request, and specifically addressing
          the activity or conduct for which you seek authorization;
        </li>
        <li>
          Solicits gambling or engages in any gambling activity that Farmyapp,
          in its sole discretion, believes is or could be construed as being
          illegal;
        </li>
        <li>
          Interferes with another user's use and enjoyment of the Farmyapp.com
          Website or any other individual's use and enjoyment of similar
          services;
        </li>
        <li>
          Refers to any website or URL that, in the sole discretion of Farmyapp,
          contains material that is inappropriate for the Farmyapp.com Website
          or any other Website, contains content that would be prohibited, or
          violates the letter or spirit of these Terms of Use.
        </li>
        <li>Harm minors in any way;</li>
        <li>
          Infringes any patent, trademark, copyright, or other proprietary
          rights or a third party’s trade secrets or rights of publicity or
          privacy, or shall not be fraudulent or involve the sale of counterfeit
          or stolen items;
        </li>
        <li>Violates any law for the time being in force;</li>
        <li>
          Deceives or misleads the addressee or users about the origin of such
          messages or communicates any information that is grossly offensive or
          menacing in nature;
        </li>
        <li>Impersonate another person;</li>
        <li>
          Contains software viruses or any other computer code, files, or
          programs designed to interrupt, destroy, or limit the functionality of
          any computer resource; or contains any Trojan horses, worms, time
          bombs, cancelbots, easter eggs, or other computer programming routines
          that may damage, detrimentally interfere with, diminish the value of,
          surreptitiously intercept, or expropriate any system, data, or
          personal information;
        </li>
        <li>
          Threatens the unity, integrity, defence, security, or sovereignty of
          The Federal Republic of Nigeria, friendly relations with foreign
          states, or public order, or causes incitement to the commission of any
          cognizable offence, prevents investigation of any offence, or insults
          any other nation.
        </li>
        <li>Shall not be false, inaccurate, or misleading;</li>
        <li>
          Shall not, directly or indirectly, offer, attempt to offer, trade, or
          attempt to trade in any item, the dealing of which is prohibited or
          restricted in any manner under the provisions of any applicable law,
          rule, regulation, or guideline for the time being in force.
        </li>
        <li>
          Shall not create liability for us or cause us to lose (in whole or in
          part) the services of our ISPs or other suppliers;
        </li>
      </ul>
      <p>
        You shall not use any "deep-link", "page-scrape", "robot", spider," or
        other automatic device, program, algorithm, or methodology, or any
        similar or equivalent manual process, to access, acquire, copy, or
        monitor any portion of the website or any content, or in any way
        reproduce or circumvent the navigational structure or presentation of
        the website or any content, to obtain or attempt to obtain any
        materials, documents, or information through any means not purposely
        made available through the Website. Farmyapp reserves the right to bar
        any such activity.
      </p>
      <p>
        You shall not attempt to gain unauthorized access to any portion or
        feature of Farmyapp.com website, or to any other systems or networks
        connected to Farmyapp.com website, or to any Farmyapp server, computer,
        or network, or to any of the services offered on or through Farmyapp.com
        Website, by hacking, password mining," or any other illegitimate means.
      </p>
      <p>
        You shall not probe, scan, or test the vulnerability of Farmyapp website
        or any network connected to Farmyapp.com website, nor breach the
        security or authentication measures on Farmyapp.com website or any
        network connected to the Website.
      </p>
      <p>
        You may not reverse look-up, trace, or seek to trace any information on
        any other user of or visitor to Farmyapp.com Website, or any other
        customer of Farmyapp.com, including any Farmyapp.com account not owned
        by you, to its source, or exploit Farmyapp.com Website or any service or
        information made available or offered by or through Farmyapp.com Website
        in any way where the purpose is to reveal any information, including but
        not limited to personal identification or information, other than your
        own information, as provided for by Farmyapp.com Website.
      </p>
      <p>
        You agree that you will not take any action that imposes an unreasonable
        or disproportionately large load on the infrastructure of Farmyapp.com
        website or Farmyapp’s systems or networks, or any systems or networks
        connected to Farmyapp.com.
      </p>
      <p>
        You agree not to use any device, software, or routine to interfere or
        attempt to interfere with the proper working of the Website or any
        transaction being conducted on the website, or with any other person’s
        use of the Website.
      </p>
      <p>
        You may not forge headers or otherwise manipulate identifiers in order
        to disguise the origin of any message or transmittal you send to
        Farmyapp.com on or through the Website or any service offered on or
        through the Website. You may not pretend that you are, or that you
        represent, someone else, or impersonate any other individual or entity.
      </p>
      <p>
        You may not use the website or any content for any purpose that is
        unlawful or prohibited by these Terms of Use or to solicit the
        performance of any illegal activity or other activity that infringes the
        rights of Farmyapp.com or others.
      </p>
      <p>
        Solely to enable Farmyapp.com to use the information you supply us with,
        so that we are not violating any rights you might have in your
        Information, you agree to grant us a non-exclusive, worldwide,
        perpetual, irrevocable, royalty-free, sub-licensable (through multiple
        tiers) right to exercise the copyright, publicity rights, database
        rights, or any other rights you have in your Information, in any media
        now known or not currently known, with respect to your Information.
        Farmyapp.com will only use your Information in accordance with the terms
        of use and Farmyapp’s Privacy Policy.
      </p>
      <p>
        From time to time, you shall be responsible for providing information
        relating to the items or services proposed to be sold by you. In this
        connection, you undertake that all such information will be accurate in
        all respects.
      </p>
      <p>
        You shall not exaggerate or overemphasize the attributes of such items
        or services so as to mislead other users in any manner.
      </p>
      <p>
        You shall not copy and paste images of products you want to sell on
        Farmyapp.com from other websites; the images to be used must be original
        images and not stolen images or content posted on another site. And in
        the event that you violate this term, you hereby indemnify Farmyapp from
        any third-party claim thereon.
      </p>
      <p>
        You shall not engage in advertising to, or solicitation of, other users
        of Farmyapp.com to buy or sell any products or services, including, but
        not limited to, products or services related to those displayed on
        Farmyapp.com or related to Farmyapp.com.
      </p>
      <p>
        You may not transmit any chain letters or unsolicited commercial or junk
        email to other users via Farmyapp.com. It shall be a violation of these
        Terms of Use to use any information obtained from Farmyapp.com in order
        to harass, abuse, or harm another person, or in order to contact,
        advertise to, solicit, or sell to another person outside of Farmyapp.com
        without Farmyapp’s prior explicit consent. In order to protect our users
        from such advertising or solicitation, Farmyapp reserves the right to
        restrict the number of messages or emails that a user may send to other
        users in any 24-hour period that Farmyapp deems appropriate in its sole
        discretion.
      </p>
      <p>
        You understand that Farmyapp has the right at all times to disclose any
        information (including the identity of the persons providing information
        or materials on the Farmyapp.com Website) as necessary to satisfy any
        law, regulation, or valid governmental request. This may include,
        without limitation, disclosure of the information in connection with an
        investigation of alleged illegal activity or solicitation of illegal
        activity or in response to a lawful court order or subpoena. In
        addition, we can (and you hereby expressly authorize us to) disclose any
        information about you to law enforcement or other government officials,
        as we, in our sole discretion, believe necessary or appropriate in
        connection with the investigation and/or resolution of possible crimes,
        especially those that may involve personal injury.
      </p>
      <p>
        Farmyapp reserves the right, but has no obligation, to monitor the
        materials posted on Farmyapp.com website. Farmyapp shall have the right
        to remove or edit any content that, in its sole discretion, violates, or
        is alleged to violate, any applicable law or either the spirit or letter
        of these Terms of Use. Notwithstanding this right of Farmyapp.com.
      </p>
      <p>
        <b>
          YOU REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF THE MATERIALS YOU
          POST ON THE Farmyapp.com WEBSITE AND IN YOUR PRIVATE MESSAGES.
        </b>
      </p>
      <p>
        Please be advised that such content posted does not necessarily reflect
        the views of Farmyapp.com. In no event shall Farmyapp assume or have any
        responsibility or liability for any content posted or any claims,
        damages, or losses resulting from the use of and/or appearance of
        content on Farmyapp.com. You hereby represent and warrant that you have
        all necessary rights in and to all content you provide and all
        information it contains, and that such content shall not infringe any
        proprietary or other rights of third parties or contain any libellous,
        tortious, or otherwise unlawful information.
      </p>
      <p>
        Your correspondence or business dealings with, or participation in
        promotions of, advertisers found on or through Farmyapp.com, including
        payment and delivery of related goods or services, and any other terms,
        conditions, warranties, or representations associated with such
        dealings, are solely between you and such advertiser. Farmyapp shall not
        be responsible or liable for any loss or damage of any sort incurred as
        the result of any such dealings or as the result of the presence of such
        advertisers on Farmyapp.com.
      </p>
      <p>
        It is possible that other users (including unauthorized users or
        "hackers") may post or transmit offensive or obscene materials on
        Farmyapp.com and that you may be involuntarily exposed to such offensive
        and obscene materials. It is also possible for others to obtain personal
        information about you due to your use of Farmyapp.com, and the recipient
        may use such information to harass or injure you. Farmyapp does not
        approve of such unauthorized uses, but by using the Farmyapp.com
        website, you acknowledge and agree that Farmyapp will not be responsible
        for the use of any personal information that you publicly disclose or
        share with others on Farmyapp.com. Carefully select the type of
        information that you will publicly disclose or share with others on
        Farmyapp.com.
      </p>
      <p>
        Farmyapp shall have all the rights to take necessary action and claim
        damages that may occur due to your involvement or participation in any
        way, on your own or through a group(s) of People, intentionally or
        unintentionally, in DoS (Denial of Service) or DDoS (Distributed Denial
        of Service).
      </p>
      <h3>Contents Posted on Site</h3>
      <p>
        All text, graphics, user interfaces, visual interfaces, photographs,
        trademarks, logos, sounds, music, artwork, and computer code
        (collectively, "Content"), including but not limited to the design,
        structure, selection, coordination, expression, "look and feel", and
        arrangement of such content, contained on Farmyapp.com is owned,
        controlled, or licensed by or to Phi Agribusiness Agritech Development
        Ent. and is protected by trade dress, copyright, patent, and trademark
        laws, and various other intellectual property rights and unfair
        competition laws.
      </p>
      <p>
        Except as expressly provided in these Terms of Use, no part of
        Farmyapp.com and no Content may be copied, reproduced, re-published,
        uploaded, posted, publicly displayed, encoded, translated, transmitted,
        or distributed in any way (including "mirroring") to any other computer,
        server, website, or other medium for publication or distribution or for
        any commercial enterprise without Farmyapp’s express prior written
        consent. You may use information on Farmyapp.com products and services
        purposely made available by Farmyapp.com for downloading from the site,
        provided that you
      </p>
      <ol>
        <li>
          Do not remove any proprietary notice language from all copies of such
          documents.
        </li>
        <li>
          Use such information only for your personal, non-commercial
          informational purposes, and do not copy or post such information on
          any networked computer or broadcast it in any media.
        </li>
        <li>make no modifications to any such information, and</li>
        <li>
          not make any additional representations or warranties relating to such
          documents.
        </li>
      </ol>
      <p>
        You shall be responsible for any notes, messages, e-mails, billboard
        postings, photos, drawings, profiles, opinions, ideas, images, videos,
        audio files, or other materials or information posted or transmitted to
        the Sites (collectively, ‘’Content"). Such Content will become the
        property of Farmyapp, and you grant Phi Agribusiness Agritech
        Development Ent. worldwide, perpetual, and transferable rights in such
        Content. Farmyapp shall be entitled to, consistent with our Privacy
        Policy, use the Content or any of its elements for any type of use
        forever, including but not limited to promotional and advertising
        purposes, in any media now known or hereafter devised, including the
        creation of derivative works that may include the Content you provide.
        You agree that any Content you post may be used by Farmyapp, consistent
        with our Privacy Policy and Rules of Conduct on the site as mentioned
        herein, and you are not entitled to any payment or other compensation
        for such use.
      </p>
      <h3>Other Businesses</h3>
      <p>
        Farmyapp does not take responsibility or liability for the actions,
        products, content, and services on the Farmyapp.com website that are
        linked to Affiliates and/or third-party websites using Farmyapp.com APIs
        or otherwise. In addition, Farmyapp.com may provide links to the
        third-party websites of affiliated companies and certain other
        businesses for which Farmyapp assumes no responsibility for examining or
        evaluating the products and services offered by them, and Farmyapp does
        not warrant the offerings of any of these businesses or individuals or
        the content of such third-party website(s). Farmyapp.com does not in any
        way endorse any third-party website(s) or content thereof.
      </p>
      <h3>Links</h3>
      <p>
        Farmyapp.com welcomes links to this site. You may establish a hypertext
        link to the Farmyapp.com website, provided that the link does not state
        or imply any sponsorship or endorsement of your site by Farmyapp.com.
        You must not use on your site or in any other manner any trademarks,
        service marks, or other materials appearing on Farmyapp.com, including
        any logos or characters, without the express written consent of
        Farmyapp.com and the owner of the mark or materials. You must not frame
        or otherwise incorporate into another third-party website or present in
        conjunction with or juxtaposed against such a website any of the content
        or other materials on the Farmyapp.com website without Farmyapp’s prior
        written consent.
      </p>
      <h3>Privacy</h3>
      <p>
        We view the protection of Your privacy as a very important principle. We
        understand clearly that you and your Personal Information are one of our
        most important assets. We store and process your Information on
        computers that may be protected by physical as well as reasonable
        technological security measures and procedures in accordance with the
        Information Technology Act 2000 and rules thereunder. Our current
        Privacy Policy is available at Privacy-policy. If you object to your
        Information being transferred or used in this way, please do not use the
        Farmyapp.com website. Information submitted may be made available to
        Farmyapp partners, who can in turn use it for verification purposes.
      </p>
      <h3>Mandatory Declaration</h3>
      <p>
        You hereby declare that you have provided all necessary Legal Delivery
        Details for the shipment of the product to the destination specified by
        you in the Order.
      </p>
      <p>
        You further declare that the Product will be acquired for personal use
        by the Consignee and/or Buyer. The Product will not be sold, resold,
        bartered, or in any way disposed of for profit.
      </p>
      <h3>Disclaimer of Warranties and Liability</h3>
      <p>
        This website and all the materials, products (including but not limited
        to software), and services included on or otherwise made available to
        you through this site are provided on Farmyapp.com on an "as is" and "as
        available" basis without any representation or warranty, express or
        implied, except otherwise specified in writing. Without prejudice to the
        foregoing paragraph, Farmyapp.com does not warrant that:
      </p>
      <ul>
        <li>
          This website will be constantly available, or available at all. or
        </li>
        <li>
          The information on this website is complete, true, accurate, or
          non-misleading.
        </li>
      </ul>
      <p>
        Farmyapp will not be liable to you in any way or in relation to the
        contents of, use of, or otherwise in connection with the Farmyapp.com
        website.
      </p>
      <p>
        Farmyapp does not warrant that this site, information, content,
        materials, products (including software), or services included on or
        otherwise made available to you through the Farmyapp.com website, their
        servers, or electronic communication sent from Farmyapp.com are free of
        viruses or other harmful components. Nothing on Farmyapp.com
        constitutes, or is meant to constitute, advice of any kind.
      </p>
      <p>
        All the products sold on Farmyapp.com are governed by the laws of The
        Federal Republic of Nigeria, and if Farmyapp is unable to deliver such
        products due to the implications of the laws, Farmyapp will return or
        give credit for the amount received by Farmyapp from the sale of such
        products that could not be delivered to you.
      </p>
      <p>
        You will be required to enter a valid phone number while placing an
        order on Farmyapp.com. By registering your phone number with us, you
        consent to being contacted by Farmyapp via phone calls and/or SMS
        notifications in case of any order, shipment, or delivery-related
        updates. Farmyapp will not use your personal information to initiate any
        promotional phone calls or SMS.
      </p>
      <h3>Services</h3>
      <h4>General Delivery Terms</h4>
      <p>
        After the confirmation of an order, necessary steps will be taken to
        keep you updated about the expected delivery date and time.
        Specifically, once the order is dispatched to your selected delivery
        location, we will notify you of the projected delivery time. Please note
        that our delivery partners will only stay at a specific location for up
        to 15 minutes before moving on. Once the 15-minute waiting period has
        expired, the delivery partner will move to the next address, and another
        requests for delivery will incur an additional payment on your behalf.
        Defiance to pay the applicable costs will result in a refund of the
        purchase price, subject to any deductions determined by Farmyapp.
        Farmyapp will not be held accountable for any change in the quality of
        the items as a result of the change in delivery date if the change is
        initiated by you after the order has been shipped. Farmyapp shall
        reimburse the purchase price subject to a cancellation fee as determined
        by Farmyapp If an order is cancelled after it has been shipped.
      </p>
      <p>
        Additionally, kindly note that our delivery partners are not required to
        enter your house or compound for security reasons. As a result, orders
        will be considered delivered after the product has entered the premises.
        Please bear in mind that our delivery partners have been advised to turn
        down any request granting them access to our customers’ personal
        quarters.
      </p>
      <p>
        When the order is delivered to you, you will verify it within 10 minutes
        to ensure that it meets the specifications specified.
      </p>
      <p>
        Farmyapp will not be responsible for any loss or damage to the product
        after it has been inspected and accepted by a third party in an instance
        where an order placed by a customer is delivered to a third party. We
        are released from all liability after delivery is finalized, i.e. when
        the customer or any third party accepts the product.
      </p>
      <h4>Payment</h4>
      <p>
        While utilizing any of the payment method(s) provided at Farmyapp.com,
        Farmyapp.com will not be responsible for or bear any liability,
        whatsoever for any loss or damage suffered directly or indirectly by you
        as a result of:
      </p>
      <ol>
        <li>Lack of authorization for any transaction(s), or</li>
        <li>
          Exceeding the preset limit mutually agreed upon by you and your
          "Bank(s)", or
        </li>
        <li>Any payment difficulties emerging from the transaction, or</li>
        <li>Transactions decline for any other reason.</li>
      </ol>
      <p>
        All payments made against Farmyapp.com purchases or services by you must
        be made in Nigerian Naira, as approved by the Central Bank of Nigeria.
        Farmyapp.com does not accept any other form of payment for purchases
        made on Farmyapp.
      </p>
      <h4>Wallet</h4>
      <p>
        The Wallet ("Wallet") is a prepaid payment mechanism that is linked to
        your Farmyapp.com account. You can keep an amount of money with
        Farmyapp.com and redeem it at your convenience. Farmyapp.com will not
        pay any interest on the amount maintained by you in your Wallet.
      </p>
      <p>
        Refill of Wallet: Your Wallet can be refilled using any of the pre-paid
        payment alternatives. You will be able to replenish your Wallet up to
        N1,000,000 in a single transaction. There is no requirement to keep a
        minimum balance.
      </p>
      <p>
        Refund of Wallet Amount: Your wallet contains three types of balances:
        Store credit (credit granted by Farmyapp.com typically in lieu of a
        cancelled order), Top-up balance (a prepaid amount added to your wallet
        to pay for future Farmyapp.com orders), and Promotional balance (credit
        granted by Farmyapp.com purely for promotional or goodwill purposes).
        Top-up balances (prepaid amounts) and promotional balances put into the
        Wallet are non-refundable, while store credit will remain fully
        refundable.
      </p>
      <p>
        Limitation of Liability: Farmyapp.com will in no event be liable for any
        special, incidental, indirect, or consequential damages or losses of any
        kind originating from the use of Wallet or associated with the Wallet.
      </p>
      <h4>Breach</h4>
      <p>
        Farmyapp.com may, without limiting other remedies, restrict your
        activity, instantly withdraw your information, alert other users of your
        actions, briefly or indefinitely suspend, terminate, or block your
        membership, and/or refuse to provide you with access to the Farmyapp.com
        website in the event of, but not limited to:
      </p>
      <p>
        Farmyapp.com may, without limiting other remedies, restrict your
        activity, instantly withdraw your information, alert other users of your
        actions, briefly or indefinitely suspend, terminate, or block your
        membership, and/or refuse to provide you with access to the Farmyapp.com
        website in the event of, but not limited to:
      </p>
      <ol>
        <li>
          If you breach the Terms of Use, Privacy Policy, or other rules and
          policies, if any,
        </li>
        <li>
          If Farmyapp.com is unable to verify or authenticate any information
          you provide,
        </li>
        <li>
          If it is believed that your actions may cause legal liability for you
          or other users of Farmyapp.com,
        </li>
        <li>
          Farmyapp.com may at any time, at its sole discretion, restore
          suspended users.
        </li>
      </ol>
      <p>
        A suspended or blocked user may not register or attempt to register with
        Farmyapp.com or use the Farmyapp.com Website in any way until
        Farmyapp.com restores such user. Regardless of the foregoing, if you
        violate the Terms of Use, Privacy Policy, or other rules and policies,
        Farmyapp.com reserves the right to recover any amounts owed to
        Farmyapp.com and to take strict legal action, including but not limited
        to referring you to the appropriate law enforcement or other authorities
        for the purposes of initiating criminal or other proceedings against
        you.
      </p>
      <h4>Indemnity</h4>
      <p>
        You agree to indemnify and hold Farmyapp.com, its owner, licensee,
        affiliates, subsidiaries, group companies (as applicable), and their
        respective officers, directors, agents, and employees harmless from any
        claim, demand, or action made by you or any third party, or penalty
        imposed due to or arising out of your use of the goods or services, or
        your violation of these Terms of Use, the Privacy Policy, or other
        Policies, or your violation of any law, rule, or regulations, or the
        rights of a third party on Farmyapp.com.
      </p>
      <h4>Applicable Law</h4>
      <p>
        The laws of The Federal Republic of Nigeria shall govern, interpret, and
        construe this Agreement. The only seat of jurisdiction shall be Ibadan,
        Nigeria.
      </p>
      <h4>Jurisdictional Issues/Sale in Nigeria Only</h4>
      <p>
        The content on Farmyapp.com is presented mainly for the purpose of sale
        in Nigeria, unless otherwise stated. Farmyapp makes no representation
        that materials or services on Farmyapp.com are suitable or available for
        use in locations or countries other than Nigeria. Those who access this
        site from other locations or countries other than Nigeria do so at their
        own risk, and Farmyapp is not liable for the supply of goods or
        services, the availability of services, refunds for goods or services
        ordered from locations or countries other than Nigeria, or compliance
        with local laws, if and to the extent local laws apply.
      </p>
      <h4>Trademark, Copyright and Restriction</h4>
      <p>
        Phi Agribusiness Agritech Development Ent. owns and operates this
        website. All material on this site, including photographs, graphics,
        audio clips, and video clips, is protected by copyrights, trademarks,
        and other intellectual property rights that we own and control or that
        have been licensed to us by third parties. The information on the
        Farmyapp.com website that we own, operate, licence, or control is purely
        for your personal, non-commercial use. You must not copy, reproduce,
        republish, upload, post, transmit, or distribute such material in any
        manner, including by e-mail or other electronic means, either directly
        or indirectly, and you must not assist anyone else in doing so.
        Modification of the materials, use of the materials on any other web
        site or networked computer environment, or use of the materials for any
        purpose other than personal without the owner's prior written consent is
        a violation of the owner's copyrights, trademarks, and other proprietary
        rights and is prohibited. Any use for which you obtain compensation,
        whether monetary or otherwise, is considered a commercial use for the
        purposes of this section
      </p>
      <h4>Copyright complaint</h4>
      <p>
        We at Farmyapp respect other people's intellectual property. If you
        believe that your work has been duplicated in a way that violates your
        copyright, please contact us at help@Farmyapp.com.
      </p>
      <h4>Risk of loss</h4>
      <p>
        All Farmyapp.com purchases are done under the terms of a shipment
        contract. This implies that the lOgistics Company will bear the risk of
        loss until the item is transferred to You. If items are damaged after
        delivery, the customer bears the risk.
      </p>
      <h4>Product and Service Description</h4>
      <p>
        Farmyapp.com makes every effort to be as accurate as possible.
        Farmyapp.com, on the other hand, makes no warranty that product or
        service descriptions or other content on this site are accurate,
        complete, reliable, current, or error-free. If a product or service
        supplied on Farmyapp.com does not meet your expectations, your only
        option is to return it in unused condition.
      </p>
      <h4>Pricing and Typographical error</h4>
      <p>
        If Farmyapp.com discovers a pricing discrepancy due to typographical
        errors in pricing or product or service information, Farmyapp.com
        reserves the right to correct the discrepancy, cancel the order(s), and
        refund any monies collected from the customer within 10–15 business days
        of such corrective action being taken. The refund method will be the
        same as when the order was placed. Refunds for cash-on-delivery orders
        will be made by cheque.
      </p>
      <h4>Cancellation of order</h4>
      <p>
        Farmyapp.com retains the right to cancel any order without explanation
        if Farmyapp.com is unable to meet the criteria of the order placed, if
        the order thus placed or cancelled does not conform with Farmyapp.com
        policy, or for any other reason. Farmyapp.com shall, however, guarantee
        that any notice of order cancellation is sent to the concerned
        individual within a reasonable time and that any applicable
        reimbursement is given within a reasonable time.
      </p>
      <h4>Termination of Service</h4>
      <p>
        Farmyapp.com reserves the absolute right, with or without prior notice,
        to alter or discontinue, temporarily or permanently, any and all
        elements of our goods and services. You agree that Farmyapp.com will not
        be liable to you or any third party in the event that the availability
        of goods or services on the website is modified or discontinued.
      </p>
      <h4>Limitation of Liability</h4>
      <p>
        Farmyapp.com WILL IN NO EVENT BE LIABLE FOR ANY SPECIAL, INCIDENTAL,
        INDIRECT, OR CONSEQUENTIAL DAMAGES OR LOSSES OF ANY KIND IN CONNECTION
        WITH THIS AGREEMENT, EVEN IF farmyapp.com HAS BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES IN ADVANCE. Farmyapp's AGGREGATE LIABILITY
        TO YOU, ANY INDIVIDUAL, OR ANY THIRD PARTY ARISING OUT OF, RELATED TO,
        OR CONNECTED TO OUR GOODS AND SERVICES IS AT THE COMPANY'S SOLE
        DISCRETION
      </p>
      <p>General</p>
      <p>
        Farmyapp's Terms and Conditions and Privacy Policy, as well as other
        governing policies for Farmyapp.com, comprise the whole agreement
        between the parties with respect to the subject matter hereof. If
        Farmyapp or you choose to waive any breach or default under this
        agreement, the waiver will not apply to any subsequent or preceding
        breach or default. Failure by any party to exercise any of its rights
        under this Agreement shall not be construed as a waiver or forfeiture of
        such rights or any other rights afforded hereunder. The failure of
        either party to insist immediately on strict compliance and or
        performance of any of the terms and conditions herein breached shall not
        constitute a waiver of either party's right, nor shall it constitute
        acquiescence therein, and the same shall not affect or impair the later
        exercise of such right, nor shall it be presumed to vary these terms and
        conditions in any way. When you place an order for products or services
        on Farmyapp.com, you are making an offer to us to purchase the items or
        service coupons. Please see our privacy policy for more information.
        Please keep in mind that all of your purchases are made either directly
        from a Seller if the item is "Sold by Seller" or from a Farm owner if
        the goods or services are offered by the farmer. In light of the
        foregoing, goods or services purchased from a Seller are strictly
        redeemable from sellers, but those purchased via a farmer are redeemable
        from Farmers.
      </p>
      <h4> Our Contact</h4>
      <p>
        Please address any questions or comments about this site (including
        inquiries about copyright infringement) to help@Farmyapp.com or to our
        office at No. 1 Agbofieti, off Apata Road, Ibadan.
      </p>
    </div>
  );
}

export default TAndC;
