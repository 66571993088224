import React, {useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'

import '../styles/waitlist.css';
import Navbar from './Navbar';


function LogisticsVEdit() {
    const {slug} = useParams();
    // console.log(slug)
    const { setUserInfo } = useContext(UserContext);

    const navigate = useNavigate()

    const [vehicleType, setVehicleType] = useState("");
    const [plateNum, setPlateNum] = useState("");
    const [price, setPrice] = useState("");
    // const [image, setImage] = useState(null)

    useEffect(() => {
      axios.get('api/v1/logisticsvehicle/v/'+slug)
        .then(postInfo => {
          // console.log(postInfo.data[0].vehicleType)
          setVehicleType(postInfo.data[0].vehicleType);
          setPlateNum(postInfo.data[0].plateNum);
          setPrice(postInfo.data[0].price);
          });
    }, [slug]);

    toastr.options = {
        closeButton: true,
        progressBar: true,
        positionClass: "toast-top-right",
        timeOut: 5000,
        showMethod: "fadeIn",
        hideMethod: "fadeOut",
      };
    
      const notify = () => {
        toastr.success("Vehicle Edited successfully.", "Success Message");
      };
    
      const displayError1 = () => {
        toastr.warning("This might take sometime.", "Please be patientError");
      };

      const displayError = (x) => {
        toastr.error(x, "Error: Please check and fill the form again. File means the image you uploaded");
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        displayError1()
      
        let formData = new FormData();
      
        // Loop through the images array and append each image
        // for (let i = 0; i < images.length; i++) {
        //   formData.append('images', images[i]);
        // }
      
        formData.append('plateNum', plateNum);
        formData.append('vehicleType', vehicleType);
        formData.append('price', price);
        // formData.append('slug', slug);
      
        try {
          const response = await axios.put('api/v1/logisticsvehicle/l/'+slug, formData, {
            // withCredentials: true,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
      
          // Handle successful response
          console.log(response.data);
      
          // Fetch updated user profile or navigate to another page
          notify()
          newRedirect()
        } catch (error) {
          displayError(error.response.data.message)
          // console.error(error.response.data);
        }
      };
      
      

    const newRedirect = async (e) => {
        await axios({
            url: 'api/v1/logistics/profile',
            // withCredentials: true,
            method: "GET",
            headers: {
              // "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        }).then(response => {
            const userInfoData = response.data;
            setUserInfo(userInfoData);
            // console.log(userInfoData);
            navigate('/myprofile');
        }).catch( (err) => {
            // console.log(err.response.data.message)
            displayError(err.response.data.message)
            // console.log(formData)
        })
    }

  return (
    <div>
    <Navbar/>
      <div className='container1'>
            <form className="post_blog" onSubmit={handleSubmit}>
            <div className="waitlist_post">
            <label className="form_label">Plate Number</label>
            <input
              type="text"
              onChange={(e) => setPlateNum(e.target.value)}
              value={plateNum}
              className="form_input"
              placeholder={"Enter Vehicle Plate Number here."}
            />
          </div>
            <div className="waitlist_post">
            <label className="form_label">Vehicle Type</label>
            <select
              onChange={(e) => setVehicleType(e.target.value)}
              value={vehicleType}
              className="form_input"
            >
              <option value="">Pick vehicle Type</option>
              <option value="truck">Truck</option>
              <option value="van">Van</option>
              <option value="car">Car</option>
              <option value="bike">Bike</option>
            </select>
          </div>
          
          <div className="waitlist_post">
            <label className="form_label">Price</label>
            <input
              type="text"
              onChange={(e) => setPrice(e.target.value)}
              value={price}
              className="form_input"
              placeholder={"How much is delivery with same city."}
            />
          </div>
                
                <button className="pos_bt" >Submit</button>

            </form>
        </div>
    </div>
  )
}

export default LogisticsVEdit
