import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

import moment from "moment";
import { format } from "timeago.js";
import axios from "axios";
import { UserContext } from "../context/UserContext";
import "../styles/logOrderDetail.css";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import FMFooter from "../Components/FMFooter";
import ProfileMenu from "../Components/profileMenu";
export default function LogisticsOrderDetail() {
  const { orderId } = useParams();
  const [orderDetail, setOrderDetail] = useState(null);
  // const [newStatus, setNewStatus] = useState("");

  useEffect(() => {
    const fetchOrderDetail = async () => {
      try {
        const response = await axios.get(
          `/api/v1/logisticsbooking/${orderId}`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setOrderDetail(response.data);
      } catch (error) {
        console.error("Error fetching order detail:", error);
      }
    };

    fetchOrderDetail();
  }, [orderId]);

  const handleStatusChange = async (userInfo, orderDetail) => {
    if (!orderDetail) {
      console.error("Order detail is not available");
      return;
    }
    const { _id: userId } = userInfo;

    if (userId === orderDetail.user) {
      try {
        await axios.put(
          `/api/v1/logisticsbooking/userchange/${orderId}`,
          {
            status: "delivered",
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        const response = await axios.get(
          `/api/v1/logisticsbooking/${orderId}`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setOrderDetail(response.data.order);
      } catch (error) {
        console.error("Error updating status:", error);
      }
    } else if (userInfo.logisticsName) {
      try {
        await axios.put(`/api/v1/logisticsbooking/logchange/${orderId}`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const response = await axios.get(
          `/api/v1/logisticsbooking/${orderId}`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setOrderDetail(response.data.order);
      } catch (error) {
        console.error("Error marking order as packed:", error);
      }
    } else if (userInfo.logisticsName) {
      try {
        await axios.put(`/api/v1/logisticsbooking/logchange/${orderId}`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const response = await axios.get(
          `/api/v1/logisticsbooking/${orderId}`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setOrderDetail(response.data.order);
      } catch (error) {
        console.error("Error marking order as in transit:", error);
      }
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Paid":
        return "btn-brown";
      case "Packed":
        return "btn-blue";
      case "In transit":
        return "btn-yellow";
      case "Delivered":
        return "btn-green";
      case "Canceled":
        return "btn-red";
      case "pending":
        return "btn-black";
      default:
        return "";
    }
  };

  const totalPrice = orderDetail?.finalAmount;

  const { userInfo } = useContext(UserContext);

  return (
    <div>
      <Navbar />
      <div className="profile">
        <ProfileMenu />
        <div className="orderDetailContainer">
          <div className="logisticsOrderDetails">
            <div>
              <h1>Order Detail</h1>
              <h3>Order ID: {orderDetail?.booking?._id}</h3>
            </div>
            <div className="dataDetails">
              <h4>
                <span className="setAside">Account Type: </span>{" "}
                <span className="setDetail">
                  {orderDetail?.booking?.userType}
                </span>
              </h4>
              <h4>
                <span className="setAside">Pick Up Location: </span>{" "}
                <span className="setDetail">
                  {orderDetail?.booking?.pickupLocation}
                </span>
              </h4>
              <h4>
                <span className="setAside">Delivery Location: </span>{" "}
                <span className="setDetail">
                  {orderDetail?.booking?.deliveryLocation}
                </span>
              </h4>
              <h4>
                <span className="setAside">Phone Number: </span>{" "}
                <span className="setDetail">
                  {orderDetail?.booking?.phoneNumber}
                </span>
              </h4>
              <h4>
                <span className="setAside">Name of Reciever: </span>{" "}
                <span className="setDetail">{orderDetail?.booking?.name}</span>
              </h4>
              <h4>
                <span className="setAside">Delivery Type: </span>{" "}
                <span className="setDetail">
                  {orderDetail?.booking?.deliveryType}
                </span>
              </h4>
              <h4>
                <span className="setAside">Delivery Status: </span>{" "}
                <span className="setDetail">
                  {orderDetail?.booking?.status}
                </span>
              </h4>
              <h4>
                <span className="setAside">Total Price: </span>{" "}
                <span className="setDetail">
                  {orderDetail?.booking?.totalPrice}
                </span>
              </h4>
              <p>
                Created At:{" "}
                {moment(orderDetail?.booking?.createdAt).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}
              </p>
              <h5>
                <span className="setAside">Last Updated:</span>{" "}
                <span className="setTimeDetail">
                  {format(orderDetail?.booking?.updatedAt)}
                </span>
              </h5>
              <h4>{orderDetail?.booking?.updatedAt}</h4>
              <h3>Total Price: N{totalPrice}</h3>
            </div>
            <div
              className={`order-details-btn3 ${getStatusColor(
                orderDetail?.status
              )}`}
              onClick={() => handleStatusChange(userInfo, orderDetail)}
            >
              <h3>Change Status</h3>
            </div>

            <div className="order-detail-mobile-support">
              <i className="bx bx-comment-dots"></i>
              <p>
                Need support?
                <br />
                Chat with us
              </p>
              <p className="order-detail-mobile-support-link">chat</p>
            </div>
          </div>
        </div>
      </div>

      <div className="mfooter">
        <FMFooter />
      </div>
      <Footer className="footer" />
    </div>
  );
}
