import { useLocation, Link } from 'react-router-dom';

import { MdAgriculture, MdStoreMallDirectory } from "react-icons/md";
import { AiOutlineCar } from "react-icons/ai";
import { GoOrganization } from "react-icons/go";

// import toastr from "toastr";
// import "toastr/build/toastr.min.css";

import '../styles/signup.css'


export default function SearchOptions() {
const location = useLocation();

  return (
    <div>
        <div>
            <div className='targets'>
            <div className='signutargetm'>
             
                <Link className={`selectEach ${location.pathname === '/stores' ? 'signueach1ms' : ''}`} to='/stores'>
                    <div className={`toggle-button `}>
                        <div  className='targetIcons'><MdStoreMallDirectory/></div>
                        <div className='targetText'>
                            <div> Stores </div>
                        </div>
                        
                    </div>
                </Link>
                <Link className={`selectEach ${location.pathname === '/companies' ? 'signueach1ms' : ''}`} to='/companies'>
                    <div className={`toggle-button `}>
                        <div  className='targetIcons'><GoOrganization/></div>
                        <div className='targetText'>
                            <div> Companies </div>
                        </div>
                        
                    </div>
                </Link>
                <Link className={`selectEach ${location.pathname === '/farmersmarket' ? 'signueach1ms' : ''}`} to='/farmersmarket'>
                    <div className={`toggle-button `}>
                        <div  className='targetIcons'><MdAgriculture/></div>
                        <div className='targetText'>
                            <div>Farmer's Market</div>
                        </div>
                    </div>
                </Link>
                <Link className={`selectEach ${location.pathname === '/logistics' ? 'signueach1ms' : ''}`} to='/logistics'>
                    <div className={`toggle-button `}>
                        <div  className='targetIcons'><AiOutlineCar/></div>
                        <div className='targetText'>
                            <div>Logistics</div>
                        </div>
                    </div>
                </Link>
            </div>
           </div>
        </div>
    </div>
  )
}