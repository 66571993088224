// import React, { useContext } from "react";
// import axios from "axios";

// import { UserContext } from "../context/UserContext";
import { Link } from "react-router-dom";

// import profback from "../static/back.jpg";
// import loho from "../static/FARMY EMB green..png";
import { MdAgriculture, MdStoreMallDirectory } from "react-icons/md";
import { BsPerson, BsCart2 } from "react-icons/bs";
// import { BiLogOut } from "react-icons/bi";
import { AiOutlineCar } from "react-icons/ai";
import { IoMdNotificationsOutline } from "react-icons/io";
import { GoOrganization } from "react-icons/go";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

import "../styles/navbar.css";

// window.addEventListener('scroll', () => {
//   document.querySelector('nav').classList.toggle('window-scroll', window.scrollY > 0)
// })

function SideNav() {
  toastr.options = {
    closeButton: true,
    progressBar: true,
    positionClass: "toast-top-right",
    timeOut: 5000,
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
  };

 
  const isAuthenticated = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    return !!userInfo && userInfo.username;
  };

  return (
    <nav>
      <div className="navbar1"></div>
      <div className="">
        <div className="menuitems1">
          <Link className="menuitem" to="/stores">
            <MdStoreMallDirectory className="menuicon1" size={30} />
          </Link>
          <Link className="menuitem" to="/companies">
            <GoOrganization className="menuicon1" size={30} />
          </Link>
          <Link className="menuitem" to={"/farmersmarket"}>
            <MdAgriculture className="menuicon1" size={30} />
          </Link>
          <Link className="menuitem" to="/logistics">
            <AiOutlineCar className="menuicon1" size={30} />
          </Link>
          {isAuthenticated() && (
            <>
              <Link className="menuitem" to="/myprofile/MyCart">
                <BsCart2 className="menuicon1" size={30} />
              </Link>
              <Link className="menuitem" to="/myprofile/Notification">
                <IoMdNotificationsOutline className="menuicon1" size={30} />
              </Link>
              <Link className="menuitem" to="/myprofile">
                <BsPerson className="menuicon1" size={30} />
              </Link>
            </>
          )}
        </div>
      </div>
    </nav>
  );
}

export default SideNav;
