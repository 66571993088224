// CartPage.js
import React from "react";
import FarmCart from "../Components/FarmCart";
import StoreCart from "../Components/StoreCart";
import CompanyCart from "../Components/CompanyCart"
// import LogisticsCart from "../Components/LogisticsCart";

const CartPage = () => {
  return (
    <div>
      <div className="cart-section">
        <div>
          <h2>Farm Cart</h2>
          <FarmCart />
        </div>
        <div>
          <h2>Store Cart</h2>
          <StoreCart />
        </div>
        <div>
          <h2>Company Cart</h2>
          <CompanyCart />
        </div>
        {/* <div>
          <h2>Logistics Cart</h2>
          <LogisticsCart />
        </div> */}
      </div>
    </div>
  );
};

export default CartPage;
