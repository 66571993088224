import { Link } from "react-router-dom";
import { CiShoppingBasket } from "react-icons/ci";
import { FaStar, FaRegStar } from "react-icons/fa";

function EachFarmProduct({
  _id,
  userId,
  availabilityDate,
  images,
  measuringScale,
  perUnitPrice,
  numReviews,
  productName,
}) {
  let number = perUnitPrice;
  let x = Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(
    number
  );

  const rawDate = new Date(availabilityDate);
  const formattedDate = rawDate.toLocaleDateString();

  // Calculate the rating based on numReviews
  const rating = numReviews > 0 ? (numReviews / 5).toFixed(1) : "0.0";

  return (
    <Link className="eachproduct" to={`/farmersmarket/${_id}`}>
      <img src={images[0]} alt={productName} className="productImage" />
      {userId?.farmName && (
        <p className="farmname">
          {userId.farmName.length > 17
            ? `${userId.farmName.substring(0, 13)}...`
            : userId.farmName}
        </p>
      )}
      <p className="productname">
        {productName.length > 13
          ? `${productName.substring(0, 13)}...`
          : productName}
      </p>
      {/* <p className="productname"> {productName}</p> */}
      <div className="ratingc">
        {Array.from({ length: 5 }).map((_, index) => (
          <span key={index}>
            {index < Math.floor(rating) ? <FaStar /> : <FaRegStar />}
          </span>
        ))}
        <span className="rating"> {rating} stars</span>
      </div>
      <span className="avail"> Available on {formattedDate}</span>
      <p className="scale">
        {x} per{" "}
        {measuringScale.length > 13
          ? `${measuringScale.substring(0, 8)}...`
          : measuringScale}
      </p>
      <div className="addToCart">
        <CiShoppingBasket size="16px" />
        <span className="scale"> {"  "}Add To Cart</span>
      </div>
    </Link>
  );
}

export default EachFarmProduct;
