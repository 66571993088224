import React from "react";
import { Link } from "react-router-dom";

import glogo from "../static/FARMY EMB green..png";
import grain from "../static/grain.png";
import tom from "../static/tom.png";
import money from "../static/stor2.jpg";
import comp from "../static/store1.jpg";
import logistics from "../static/log.jpg"
import lfarm from "../static/lfarm.jpg";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

import { IoLogoAndroid } from "react-icons/io";
import { AiFillApple } from "react-icons/ai";

import "../styles/landingPage.css";
import FMFooter from "../Components/FMFooter";
import Footer from "../Components/Footer";

function Landingpage() {
  toastr.options = {
    closeButton: true,
    progressBar: true,
    positionClass: "toast-top-right",
    timeOut: 5000,
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
  };

  const notify = () => {
    toastr.warning(
      "check back after some time",
      "Sorry this functionality is not yet available for use."
    );
  };
  return (
    <div className="landin-main">
      <div className="layer">
        <div className="topBody">
          <div className="navcontainer">
            <div>
              <img src={glogo} alt="Company logo" className="comp_logo" />
            </div>
            <div className="nav-buttonContainer">
              <Link to="/farmersmarket">
                <span className="nbutton">Explore</span>
              </Link>
              {/* <Link to="/">
                <span className="nbutton">Contact Us</span>
              </Link> */}
              <Link to="/signin">
                <span className="nbutton">Login</span>
              </Link>
              <Link to="/signup">
                <span className="lbutton">Create Account</span>
              </Link>
            </div>
          </div>
          <div className="topBodyConatainer">
            <div className="topBodyConatainer1">
              <img src={tom} alt="Company logo" className="sideImage" />
              <div className="containerTop">
                <h1 className="topText">
                  {" "}
                  Buy fresh Agricultural
                  <br /> <span className="fav">
                    {" "}
                    Produce from Farmers &{" "}
                  </span>{" "}
                  Stores.
                </h1>
              </div>
              <img src={grain} alt="Company logo" className="sideImage" />
            </div>
            <div className="appContainer">
              <div className="eachIcon" onClick={notify}>
                {" "}
                <IoLogoAndroid size="40px" /> <span> Get on Android</span>
              </div>
              <div className="eachIcon" onClick={notify}>
                {" "}
                <AiFillApple size="40px" /> <span> Get on iPhone</span>
              </div>
            </div>
          </div>
        </div>
        <div className="schedule">
          <div className="schedule1">
            <h4>Schedule order</h4>
            <p>
              We know how busy your day can be fit perfectly into your busy
              routine
            </p>
          </div>
          <div className="schedule23">
            <div className="schedule2">
              <h4>Order from Farms</h4>
              <p>
                Order bulk agricultural products directly from the farm Before
                they are harvested with FarmyApp
              </p>
            </div>
            <div className="schedule3">
              <h4>Choose Logistics </h4>
              <p>
                Enjoy nation wide delivery to Send and receive items nation wide
                with FarmyApp
              </p>
            </div>
          </div>
        </div>
        <div className="regStore">
          <img src={money} alt="Company logo" className="money" />
          <div className="regstore1">
            <h1>Register your Groceries Store with us</h1>
            <p>
              Partner with us and enjoy the Joy of fast sales. We offer 24/7
              support to help you increase your daily sales and double your
              income when you register your store with us.
            </p>
            <div>
              <Link to="/signup">
                <span className="cbutton">Create Account</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="reglogistics">
          <div className="regstore1">
            <h1>Register as a logistics company with us</h1>
            <p>Join us today and make multiple income streams</p>
            <div>
              <Link to="/signup">
                <span className="cbutton">Join us now!</span>
              </Link>
            </div>
          </div>
          <img src={logistics} alt="Company logo" className="money" />
        </div>
        <div className="regStore">
          <img src={comp} alt="Company logo" className="money" />
          <div className="regstore1">
            <h1>Register your Food Wholesales Company with us</h1>
            <p>
              Partner with us and enjoy the Joy of fast sales. We offer 24/7
              support to help you increase your daily sales and double your
              income when you register your whole sale company with us.
            </p>
            <div>
              <Link to="/signup">
                <span className="cbutton">Create Account</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="reglogistics1">
          <img src={lfarm} alt="Company logo" className="money" />
          <div className="regstore1">
            <h1>Become our favourite customer</h1>
            <p>
              Become our favourite customer to Make more by becoming a referrer
              on farmyApp
            </p>
            <div>
              <Link to="/signup">
                <span className="cbutton">Refer now!</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="together">
          <h1>Together, let’s keep spreading Happiness.</h1>
          <div className="appContainer">
            <div className="eachIcon" onClick={notify}>
              {" "}
              <IoLogoAndroid size="40px" /> <span> Get on Android</span>
            </div>
            {"    "} {"   "} {"   "}
            <div className="eachIcon" onClick={notify}>
              {" "}
              <AiFillApple size="40px" /> <span> Get on iPhone</span>
            </div>
          </div>
        </div>
        <div className="mfooter">
        <FMFooter />
      </div>
      <Footer className="footer" />
      </div>
    </div>
  );
}

export default Landingpage;