import React, { useRef, useState, useEffect, useContext } from "react";
import axios from "axios";
import ChatBox from "../Components/ChatBox";
import { UserContext } from "../context/UserContext";

import { io } from "socket.io-client";

import "../styles/chat.css";
import { useParams } from "react-router-dom";
import Navbar from "../Components/Navbar";
import { I0_LINK } from "../config";

export default function Chat() {
  // const dispatch = useDispatch();
  const { chatId } = useParams();
  const socket = useRef();
  const { userInfo } = useContext(UserContext);

  const [chats, setChats] = useState([]);
  const [participanto, setParticipanto] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [currentChat, setCurrentChat] = useState(chatId);
  const [sendMessage, setSendMessage] = useState(null);
  const [receivedMessage, setReceivedMessage] = useState(null);

  useEffect(() => {
    const getChats = async () => {
      try {
        const response = await axios.get(`/api/v1/chat/list`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const chatsFromServer = response.data.chats;

        // Filter out the participant that matches the logged-in user
        const filteredChats = chatsFromServer.map((chat) => {
          const filteredParticipants = chat.participants.filter(
            (participant) => {
              return (
                participant._id !== userInfo._id &&
                participant.username !== userInfo.username
              );
            }
          );

          return { ...chat, participants: filteredParticipants };
        });

        setChats(filteredChats);
      } catch (error) {
        console.log("Error fetching chats:", error);
      }
    };

    getChats();
  }, [userInfo._id, userInfo.username]);

  const io_link = I0_LINK;
  useEffect(() => {
    socket.current = io(io_link);
    socket.current.emit("new-user-add", userInfo._id);
    socket.current.on("get-users", (users) => {
      setOnlineUsers(users);
    });
  }, [userInfo, io_link]);

  // Send Message to socket server
  useEffect(() => {
    if (sendMessage !== null) {
      socket.current.emit("send-message", sendMessage);
    }
  }, [sendMessage]);

  useEffect(() => {
    socket.current.on("recieve-message", (data) => {
      setReceivedMessage(data);
    });
  }, []);

  const checkOnlineStatus = (chat) => {
    const onlineParticipantIds = onlineUsers.map((user) => user.userId);

    const onlineParticipant = chat.participants.find((participant) =>
      onlineParticipantIds.includes(participant._id)
    );

    return onlineParticipant ? true : false;
  };

  return (
    <div className="chatContainer">
      <Navbar />

      <div className="Chat">
        {/* Left Side */}
        <div className="Left-side-chat">
          <div className="Chat-container">
            <h2>Chats</h2>
            <div className="Chat-list">
              {chats.map((chat, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setCurrentChat(chat.chatId);
                    setParticipanto(chat);
                  }}
                >
                  <div className="follower conversation">
                    <div className="flexIt">
                      {checkOnlineStatus(chat) && (
                        <div className="online-dot"></div>
                      )}
                      {chat.participants.map(
                        (participant, participantIndex) => (
                          <div key={participantIndex}>
                            {participant.avatar && (
                              <img
                                src={participant.avatar}
                                alt={`Avatar of ${participant.username}`}
                                className="followerImage"
                                style={{ width: "50px", height: "50px" }}
                              />
                            )}
                            <div
                              className="name"
                              style={{ fontSize: "0.8rem" }}
                            >
                              <span>{participant?.username}</span>

                              <p>User Type: {participant.userType}</p>
                              <span
                                style={{
                                  color: checkOnlineStatus(chat)
                                    ? "#51e200"
                                    : "",
                                }}
                              >
                                {checkOnlineStatus(chat) ? "Online" : "Offline"}
                              </span>
                            </div>
                          </div>
                        )
                      )}

                      <div className="unread">{chat.unreadMessages}</div>
                    </div>
                  </div>
                  <hr style={{ width: "85%", border: "0.1px solid #ececec" }} />
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Right Side */}
        <div className="Right-side-chat">
          <div style={{ width: "20rem", alignSelf: "flex-end" }}>
            {/* <NavIcons /> */}
          </div>

          {/* Conditionally render ChatBox */}
          {currentChat ? (
            <ChatBox
              chat={currentChat}
              participants={participanto}
              currentUser={userInfo._id}
              setSendMessage={setSendMessage}
              receivedMessage={receivedMessage} // Pass receivedMessage to ChatBox
            />
          ) : (
            <div className="placeholder">
              {/* Your placeholder content goes here */}
              <h2>Select a chat to start conversation...</h2>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
