import React, { useContext, useState, useEffect, useRef } from "react";

import { io } from "socket.io-client";
import axios from "axios";
import { format } from "timeago.js";
import InputEmoji from "react-input-emoji";

import { UserContext } from "../context/UserContext";
import "../styles/chat.css";
import profback from "../static/back.jpg";
import { I0_LINK } from "../config";

// const getMessages = await axios.get("/api/v1/chat/messages/:id"); //(id) => API.get(`/message/${id}`);
// const addMessage = await axios.post("/api/v1/chat/send", data); // (data) => API.post('/message/', data);

export default function ChatBox({
  chat,
  participants,
  currentUser,
  setSendMessage,
  receivedMessage,
}) {
  const [userData, setUserData] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const { userInfo } = useContext(UserContext);
  const socket = useRef();
  const io_link = I0_LINK;
  useEffect(() => {
    socket.current = io({ io_link });
  }, [io_link]);

  const handleChange = (newMessage) => {
    setNewMessage(newMessage);
  };

  useEffect(() => {
    // Listen for incoming messages
    socket.current.on("new-message", (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    // Clean up on unmount
    return () => {
      socket.current.disconnect();
    };
  }, []);

  // fetch messages
  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const { data } = await axios({
          url: `/api/v1/chat/messages/${chat}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        // Filter out the user with the same ID as userInfo._id
        const filteredUsers = data.users.filter(
          (user) => user._id !== userInfo._id
        );

        setUserData(filteredUsers);
        setMessages(data.messages);
      } catch (error) {
        console.log(error);
      }
    };

    if (chat !== null) fetchMessages();
  }, [chat, userInfo._id]); // Include userInfo._id in the dependencies array

  // Always scroll to last Message
  useEffect(() => {
    scroll.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    if (chat !== null) {
      const markMessagesAsRead = async () => {
        try {
          await axios({
            URL: `api/v1/chat/mark-as-read/${chat}`,
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
        } catch (error) {
          console.error(error);
        }
      };

      markMessagesAsRead();
    }
  }, [chat, currentUser._id]);

  // Send Message
  const handleSend = async (e) => {
    e.preventDefault();
    const message = {
      sender: currentUser,
      message: newMessage,
      chatId: chat,
    };
    const receiverId = userData[0]._id;
    // send message to socket server
    setSendMessage({ ...message, receiverId });
    socket.current.emit("send-message", {
      receiverId: receiverId,
      message: message,
    });
    // send message to database
    try {
      const { data } = await axios({
        url: "/api/v1/chat/send",
        data: message,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setMessages([...messages, data]);
      setNewMessage("");
    } catch {
      console.log("error");
    }
  };

  // Receive Message from parent component
  useEffect(() => {
    // console.log("Message Arrived: ", receivedMessage);
    if (receivedMessage !== null && receivedMessage.chatId === chat._id) {
      setMessages([...messages, receivedMessage.message]);
    }
  }, [receivedMessage, chat._id, messages]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend(e);
    }
  };

  const scroll = useRef();
  const imageRef = useRef();

  return (
    <>
      <div className="ChatBox-container" key={chat}>
        {chat ? (
          <>
            {/* chat-header */}
            <div className="chat-header">
              <div className="follower">
                <div className="topChatCont">
                  <img
                    src={userData?.[0]?.avatar ? userData[0].avatar : profback}
                    alt="Profile"
                    className="followerImage1"
                    style={{ width: "50px", height: "50px" }}
                  />
                  <div className="name" style={{ fontSize: "0.9rem" }}>
                    <span>
                      <p>{userData?.[0]?.username}</p>
                      <p>{userData?.[0]?.userType}</p>
                    </span>
                  </div>
                </div>
              </div>
              <hr
                style={{
                  width: "95%",
                  border: "0.1px solid #ececec",
                  marginTop: "5px",
                }}
              />
            </div>
            {/* chat-body */}
            <div className="chat-body">
              {messages.map((message) => (
                <div
                  key={message._id}
                  ref={scroll}
                  className={
                    message.sender === currentUser ? "message own" : "message"
                  }
                >
                  <p>{message.message}</p>
                  <span>{format(message.timestamp)}</span>
                </div>
              ))}
            </div>
            {/* chat-sender */}
            <div className="chat-sender">
              {/* <div onClick={() => imageRef.current.click()}>+</div> */}
              <InputEmoji
                value={newMessage}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
              <div className="send-button" onClick={handleSend}>
                Send
              </div>
              <input
                type="file"
                name=""
                id=""
                style={{ display: "none" }}
                ref={imageRef}
              />
            </div>{" "}
          </>
        ) : (
          <span className="chatbox-empty-message">
            Tap on a chat to start a conversation...
          </span>
        )}
      </div>
    </>
  );
}
