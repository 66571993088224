import { useEffect, useState } from "react";
import axios from "axios";

import EachStore from "../Components/EachStore";
import SideNav from "../Components/SideNav";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import FMFooter from "../Components/FMFooter";
import SearchOptions from "../Components/SearchOptions";
import "../styles/products.css";

export default function StoreSearch() {
  const [isLoading, setIsLoading] = useState(true);
  const [closestStores, setClosestStores] = useState([]);
  // const [setSearchMessage] = useState("");
  const [backendError, setBackendError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    function handleSuccess(position) {
      const { latitude, longitude } = position.coords;

      axios
        .get(`api/v1/store?latitude=${latitude}&longitude=${longitude}`)
        .then((response) => {
          const stores = response.data.stores;

          const filteredStores = stores.filter((store) =>
            store.storeName.toLowerCase().includes(searchTerm.toLowerCase())
          );

          if (filteredStores.length > 0) {
            setClosestStores(filteredStores);
            setBackendError(null);
          } else {
            setBackendError(
              response.data.message || " No store around you is listed on FarmyApp or no store matches your search criteria. Refer one and win up to 200k."
            );
          }

          setIsLoading(false);
        })
        .catch((error, message) => {
          console.error("Error fetching stores:", error);
          setIsLoading(false);
          setBackendError(
            "Could not determine your location or No grocery stores in your area have been approved by FarmyApp. Please tell your favorite groceries store to register now."
          );
        });
    }

    function handleError(error) {
      console.error("Error getting location:", error);
      setIsLoading(false);
      // setSearchMessage("Could not determine your location.");
    }

    navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
  }, [searchTerm]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div>
      <Navbar />
      <SearchOptions />
      <div>
        <input
          type="text"
          placeholder="Search stores by name"
          value={searchTerm}
          onChange={handleSearchChange}
          className="searchField"
        />
      </div>
      {isLoading ? (
        <div className="loading">
          <div className="loading-spinner"></div>
        </div>
      ) : (
        <div className="prodPag">
          <div className="userpages">
            <div className="sideNavs">
              <SideNav />
            </div>
            <div className="listStores">
              {backendError ? (
                <div className="emptyPage">{backendError}</div>
              ) : closestStores.length > 0 ? (
                closestStores.map((store) => (
                  <EachStore {...store} key={store.storeLocations._id} />
                ))
              ) : (
                <div className="emptyPage">
                  No store around you is listed on FarmyApp or no store matches
                  your search criteria. Use your referral code in your profile
                  page to refer groceries store around you and make up to 200k
                  on each store you refer.
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="mfooter">
        <FMFooter />
      </div>
      <Footer className="footer" />
    </div>
  );
}
