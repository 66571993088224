import React from "react";

import "../styles/tanc.css";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";

function Referral() {
  const navigate = useNavigate();
  return (
    <div className="tcbody">
      <div className="goBack">
        <button onClick={() => navigate(-1)} className="goBack">
          <BiArrowBack /> Go back
        </button>
      </div>
      <h1 className="header1">
        FarmyApp Referral Program Terms and Conditions
      </h1>
      <p>
        <strong>Last updated: Octobeer 28, 2023</strong>
      </p>
      <p>
        These Referral Program Terms and Conditions ("Terms") are entered into
        by and between FarmyApp ("FarmyApp," "we," or "us") and the participant
        in the FarmyApp Referral Program ("Referrer" or "you"). By participating
        in the FarmyApp Referral Program, you agree to be bound by these Terms.
        If you do not agree to these Terms, do not participate in the Referral
        Program.
      </p>
      <ul>
        <h3>
          <li>Program Overview</li>
        </h3>
        <p>
          The FarmyApp Referral Program ("Program") is designed to reward
          Referrers for introducing new users to the FarmyApp platform.
          Referrers can be registered users of FarmyApp who have made at least
          one successful transaction on the platform. Referrers will receive a
          referral link, which they can share with potential new users. When a
          new user registers on FarmyApp using the referral link and makes a
          purchase or sale, the Referrer is eligible to receive a referral
          reward, which is calculated as 0.35% of the total transaction amount.
          The Program is subject to these Terms and additional terms and
          policies that may be provided by FarmyApp.
        </p>
        <h3>
          <li>Eligibility</li>
        </h3>
        <ol>
          <li>
            To be eligible to participate in the Program as a Referrer, you must
            have an active and verified FarmyApp account and have successfully
            completed at least one transaction on the FarmyApp platform.
          </li>
          <li>
            FarmyApp reserves the right to modify or discontinue the Referral
            Program at any time without notice, for any reason.
          </li>
        </ol>
        <h3>
          <li>Referral Rewards</li>
        </h3>
        <ul>
        <li>
          Referral rewards are calculated as 0.35% of the total transaction
          amount generated by the referred user till each user’s referral
          benefit is up to N200,000.
        </li>
        <li>
          Referral rewards will be credited to the Referrer's FarmyApp account
          within a reasonable time frame after the completion of the qualifying
          transaction.
        </li>
        <li>
          Referral rewards can be used for purchases on the FarmyApp platform
          and are subject to FarmyApp's standard terms and conditions.
        </li>
        </ul>
        <h3>
          <li>Prohibited Conduct</li>
        </h3>
        <p>Referrers must not engage in the following prohibited conduct:</p>
        <ol>
          <li>
            <h4>False representation:</h4> You must not make false or misleading
            statements about FarmyApp or the Referral Program.
          </li>
          <li>
            <h4>Spamming:</h4> You must not engage in spamming, including the
            distribution of your referral link through unsolicited email or
            other mass communications.
          </li>
        </ol>
        <h3>
          <li>Modification and Termination</li>
        </h3>
        <ol>
          <li>
            FarmyApp reserves the right to modify or terminate the Referral
            Program at any time and for any reason, without notice.
          </li>
          <li>
            FarmyApp may suspend or terminate a Referrer's participation in the
            Program if we believe that the Referrer has violated these Terms or
            engaged in any fraudulent or deceptive conduct.
          </li>
        </ol>
        <h3>
          <li>Privacy</li>
        </h3>
        <ol>
          <li>
            By participating in the Referral Program, you agree to FarmyApp's
            Privacy Policy, which can be found on the FarmyApp website.
          </li>
          <li>
            FarmyApp may share certain information about Referrers and referred
            users in connection with the Referral Program.
          </li>
        </ol>
        <h3>
          <li>Governing Law</li>
        </h3>
        <p>
          These Terms are governed by and construed in accordance with the laws
          of Nigeria, without regard to its conflict of law principles.
        </p>
        <h3>
          <li>Contact Information</li>
        </h3>
        <p>
          If you have questions about the FarmyApp Referral Program or these
          Terms, you may contact us at farmyapp@gmail.com or info@farmyapp.com.
        </p>
        <p>
          By participating in the FarmyApp Referral Program, you acknowledge
          that you have read, understood, and agree to these Terms. FarmyApp
          reserves the right to change these Terms at any time.
        </p>
        
      </ul>
    </div>
  );
}

export default Referral;
