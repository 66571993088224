import { useEffect, useState } from "react";
import axios from "axios";

import EachCompany from "../Components/EachCompany";
import SideNav from "../Components/SideNav";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import FMFooter from "../Components/FMFooter";
import SearchOptions from "../Components/SearchOptions";
import "../styles/products.css";

export default function CompanySearch() {
  const [isLoading, setIsLoading] = useState(true);
  const [closestCompany, setClosestCompanies] = useState([]);
  // const [setSearchMessage] = useState("");
  const [backendError, setBackendError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    function handleSuccess(position) {
      const { latitude, longitude } = position.coords;

      axios
        .get(`api/v1/company?latitude=${latitude}&longitude=${longitude}`)
        .then((response) => {
          const companies = response.data.companies;

          const filteredCompanies = companies.filter((company) =>
            company.companyName.toLowerCase().includes(searchTerm.toLowerCase())
          );

          if (filteredCompanies.length > 0) {
            setClosestCompanies(filteredCompanies);
            setBackendError(null);
          } else {
            setBackendError(
              response.data.message || " No company around you is listed on FarmyApp or no company matches your search criteria. Refer one and win up to 200k."
            );
          }

          setIsLoading(false);
        })
        .catch((error, message) => {
          // console.error("Error fetching companies:", error);
          setIsLoading(false);
          setBackendError(
            "Could not determine your location or No companies in your area have been approved by FarmyApp. Please tell your favorite companies to register now."
          );
        });
    }

    function handleError(error) {
      console.error("Error getting location:", error);
      setIsLoading(false);
      // setSearchMessage("Could not determine your location.");
    }

    navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
  }, [searchTerm]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div>
      <Navbar />
      <SearchOptions />
      <div>
        <input
          type="text"
          placeholder="Search companies by name"
          value={searchTerm}
          onChange={handleSearchChange}
          className="searchField"
        />
      </div>
      {isLoading ? (
        <div className="loading">
          <div className="loading-spinner"></div>
        </div>
      ) : (
        <div className="prodPag">
          <div className="userpages">
            <div className="sideNavs">
              <SideNav />
            </div>
            <div className="listStores">
              {backendError ? (
                <div className="emptyPage">{backendError}</div>
              ) : closestCompany.length > 0 ? (
                closestCompany.map((company) => (
                  <EachCompany {...company} key={company.companyLocations._id} />
                ))
              ) : (
                <div className="emptyPage">
                  No company around you is listed on FarmyApp or no Company matches
                  your search criteria. Use your referral code in your profile
                  page to refer groceries company around you and make up to 200k
                  on each company you refer.
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="mfooter">
        <FMFooter />
      </div>
      <Footer className="footer" />
    </div>
  );
}
